import {
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { IApiObjectArray, Uuid } from './base-types';
import { fetchJson } from '../../../services/api2';
import { createFieldRefs, simpleIdObject } from './utils';

export interface ICustomColumnGroupsRequest {
  teamId: Uuid;
}

export interface ICustomColumnGroup extends IApiObjectData {
  id: Uuid;
  teamId: Uuid | null;
  columns: ICustomColumn[] | null;
}

export interface ICustomColumn {
  id: Uuid;
  groupId: Uuid;
  name: string | null;
  sortOrder: number;
}

export const CUSTOM_COLUMN_GROUP: IApiObjectType<Uuid, ICustomColumnGroup> = simpleIdObject({
  id: 'CustomColumns/Group',
  url: (id) => `CustomColumns/Group?groupId=${encodeURIComponent(id)}`
});

export const CUSTOM_COLUMN_GROUPS: IApiObjectType<
  ICustomColumnGroupsRequest,
  IApiObjectArray<ICustomColumnGroup>
> = {
  id: 'CustomColumns/Groups',
  createRefs(cache, data): void {
    return createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: CUSTOM_COLUMN_GROUP,
        params: () => item.id,
        variant: ApiObjectVariant.Small
      }))
    );
  },
  async load(cache, params, abort): Promise<IApiObjectArray<ICustomColumnGroup>> {
    const result = await fetchJson({
      url: `CustomColumns/Groups?teamId=${encodeURIComponent(params.teamId)}`,
      abort
    });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(CUSTOM_COLUMN_GROUPS, params),
      { items: result }
    ) as IApiObjectArray<ICustomColumnGroup>;
  }
};
