import styled from 'styled-components';

const LabelContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;

  .icon-label-container {
    display: flex;
  }
`;

const BooleanFieldContainer = styled.div`
  .c-boolean-field-checkbox-wrapper {
    padding: 5px;
    padding-left: 10px;
    padding-bottom: 9px;
    border: 1px solid rgb(${({ theme }) => theme.dynamicField.outline});
    border-radius: 3px;

    &:hover {
      border: 1px solid rgb(${({ theme }) => theme.dynamicField.strongHoverOutline});
    }
  }
`;

export { LabelContainerStyled, BooleanFieldContainer };
