import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchBox } from '@fluentui/react';
import { useTheme } from 'styled-components';

function ContextualFilterMenuSearchBox({ onFilterOptionsSearchChange }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div style={{ borderBottom: `1px solid rgb(${theme.detailsList.contextMenuDivider})` }}>
      <SearchBox
        onChange={onFilterOptionsSearchChange}
        placeholder={t('contextualMenu.searchBox.placeholder')}
        styles={{
          root: [
            {
              margin: '3px',
              marginLeft: '20px',
              border: 'none',
              background: `rgb(${theme.detailsList.contextMenuSearchBackground})`
            }
          ]
        }}
      />
    </div>
  );
}

ContextualFilterMenuSearchBox.propTypes = {
  onFilterOptionsSearchChange: PropTypes.func.isRequired
};

export default ContextualFilterMenuSearchBox;
