import styled from 'styled-components';
import { DetailsList } from '@fluentui/react';

const IntegrationWrapper = styled.div`
  .c-selected-instance_wrapper {
    border: 1px solid rgb(${({ theme }) => theme.dynamicField.outline});
    border-radius: 3px;
    padding: 10px 10px 10px 10px;

    &:hover {
      border: 1px solid rgb(${({ theme }) => theme.dynamicField.strongHoverOutline});
    }
  }

  .c-selected-instance_label {
    display: flex;
    justify-content: flex-end;

    border-bottom: 1px solid rgb(${({ theme }) => theme.dynamicField.outline});

    padding-left: 8px;
    align-items: center;
  }

  .c-selected-instance_name {
    color: rgb(${({ theme }) => theme.dynamicField.fieldForeground});
    font-size: 14px;
    font-weight: 400;
  }

  .ms-DetailsList-headerWrapper {
    position: sticky;
    top: 0;
    z-index: 100;
  }
`;

const DetailsListStyled = styled(DetailsList)`
  .ms-DetailsHeader {
    padding-top: 0;
  }
`;

export { IntegrationWrapper, DetailsListStyled };
