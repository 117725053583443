import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Checkbox, DefaultButton } from '@fluentui/react';

export default function ContextualFilterMenuFilterOption({ option, onFilterOptionChange }) {
  const onChange = debounce(() => {
    onFilterOptionChange({ ...option, checked: !option.checked });
  }, 100);

  return (
    <DefaultButton
      styles={{
        root: { border: 'none', height: '36px', width: '100%', background: 'none' },
        flexContainer: { justifyContent: 'left' }
      }}
      onClick={onChange}
    >
      <Checkbox
        styles={{
          text: {
            marginLeft: '15px',
            maxWidth: '240px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        }}
        checked={option.checked}
        ariaSetSize={15}
        label={option.text}
      />
    </DefaultButton>
  );
}

ContextualFilterMenuFilterOption.propTypes = {
  option: PropTypes.shape({
    checked: PropTypes.bool,
    key: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  onFilterOptionChange: PropTypes.func.isRequired
};
