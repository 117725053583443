/* eslint-disable react/jsx-props-no-spreading */

import { ProjectProvider } from 'features/Projects/ProjectContext';
import {
  DownloadDocumentContainer,
  Header,
  MsTeamsHeader,
  Navigation,
  NavigationPanel,
  NotificationsPanel,
  RedirectContainer,
  ReleaseNotesPanel,
  SideBar,
  TrainerPanel,
  WopiContainer
} from 'layouts';
import MsTeamsConfig from 'pages/MsTeamsConfig/MsTeamsConfig';
import PropTypes from 'prop-types';
import { Suspense, lazy, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { isInIframe } from 'utils/helpers';

const Analytics = lazy(() => import('pages/Analytics'));
const Administration = lazy(() => import('pages/Administration'));
const NoAccess = lazy(() => import('pages/NoAccess'));
const NotFound = lazy(() => import('pages/NotFound'));
const ProcessDesigner = lazy(() => import('pages/ProcessDesignerSxs'));
const ProcessInstanceTree = lazy(() => import('pages/ProcessInstanceTree/ProcessInstanceTreeSxs'));
const ProcessInstances = lazy(() => import('pages/ProcessInstances'));
const Projects = lazy(() => import('pages/Projects'));
const ProcessTemplates = lazy(() => import('pages/ProcessTemplates'));
const Tags = lazy(() => import('pages/Tags/Tags'));
const TagSearch = lazy(() => import('pages/Tags/TagSearch'));
const TasksContainer = lazy(() => import('pages/Tasks'));
const TaskTemplates = lazy(() => import('pages/TaskTemplates'));
const ProjectGantt = lazy(() => import('pages/ProjectGantt/ProjectGantt'));
const ProjectTemplates = lazy(() => import('pages/ProjectTemplates'));

const Debug = lazy(() => import('pages/Debug'));

// styles
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  min-height: 0;
  height: 100%;

  .content-main {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .min-h-full {
    min-height: 100%;
  }

  .navigation-wrapper {
    @media (max-width: ${breakpoints.smallMin}px) {
      display: none;
    }
  }
`;

const SideBarStyledContainer = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.mediumMax}px) {
    display: none;
  }
`;

function Routing({ logout, contentToShow }) {
  const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
  const [showReleaseNotesPanel, setShowReleaseNotesPanel] = useState(false);
  const [showTrainerPanel, setShowTrainerPanel] = useState(false);

  const navigate = useNavigate();

  const noAccess = window.location.href.indexOf('/access') > -1;

  function onToggleNotifications() {
    setShowNotificationsPanel((prevState) => !prevState);
  }

  function onToggleReleaseNotes() {
    setShowReleaseNotesPanel((prevState) => !prevState);
  }

  function onToggleTrainerPanel() {
    setShowTrainerPanel((prevState) => !prevState);
  }

  function openAdministration(event) {
    event.preventDefault();

    navigate(`/administration`);
  }

  if (noAccess) {
    return (
      <Routes>
        <Route path="/access" element={<NoAccess />} />
      </Routes>
    );
  }

  const isMsTeamsAuthPage = window.location.href.includes('teams-config');

  if (isMsTeamsAuthPage) {
    return (
      <Routes>
        <Route path="/teams-config/:context" element={<MsTeamsConfig />} />
        <Route path="/teams-config" element={<MsTeamsConfig />} />
      </Routes>
    );
  }

  if (window.location.href.includes('file-download')) {
    return (
      <Routes>
        <Route path="/file-download/:fileId/:tenantId" element={<DownloadDocumentContainer />} />
      </Routes>
    );
  }

  if (window.location.href.includes('wopi-file')) {
    return (
      <Routes>
        <Route path="/wopi-file/:fileId/:tenantId" element={<WopiContainer />} />
      </Routes>
    );
  }

  if (window.location.href.includes('signin-oidc')) {
    return (
      <Routes>
        <Route path="/signin-oidc" element={<RedirectContainer />} />
      </Routes>
    );
  }

  const header = isInIframe() ? (
    <MsTeamsHeader
      contentToShow={contentToShow}
      logout={logout}
      onToggleNotifications={onToggleNotifications}
      onToggleTrainer={onToggleTrainerPanel}
      onToggleReleaseNotes={onToggleReleaseNotes}
    />
  ) : (
    <Header
      logout={logout}
      onToggleTrainer={onToggleTrainerPanel}
      onToggleNotifications={onToggleNotifications}
      onToggleReleaseNotes={onToggleReleaseNotes}
    />
  );

  const sidebar =
    !noAccess || !isInIframe() ? (
      <SideBarStyledContainer>
        <SideBar onAdministrationButtonClick={openAdministration}>
          <Navigation />
        </SideBar>
      </SideBarStyledContainer>
    ) : null;

  const notificationsPanel = (
    <NotificationsPanel
      showNotificationsPanel={showNotificationsPanel}
      onDismiss={onToggleNotifications}
    />
  );

  const releaseNotesPanel = (
    <ReleaseNotesPanel
      showReleaseNotesPanel={showReleaseNotesPanel}
      onDismiss={onToggleReleaseNotes}
    />
  );

  return (
    <PageWrapper>
      {header}
      <ContentWrapperStyled>
        {sidebar}
        <NavigationPanel>
          <Navigation />
        </NavigationPanel>
        <Suspense fallback={<div />}>
          <ProjectProvider>
            <Routes>
              {/* Tasks */}
              <Route path="" element={<TasksContainer />} />
              <Route path="/tasks/:filterType" element={<TasksContainer />}>
                <Route path="start-process/:startProcessId" element={<TasksContainer />} />
                <Route path=":taskId/:tab" element={<TasksContainer />} />
                <Route path=":taskId" element={<TasksContainer />} />
              </Route>

              {/* Teams */}
              <Route path="/teams/:teamId" element={<TasksContainer />}>
                <Route path="start-process/:startProcessId" element={<TasksContainer />} />
                <Route path=":taskId/:tab" element={<TasksContainer />} />
              </Route>

              <Route path="/task-templates" element={<TaskTemplates />} />
              <Route path="/task-templates/:taskId" element={<TaskTemplates />} />

              {/* Project templates */}
              <Route path="/projects/templates">
                <Route path="" element={<ProjectTemplates />} />
                <Route path="gantt/:templateId" element={<ProjectGantt />}>
                  <Route path="" element={<ProjectGantt />} />
                  <Route path=":taskId/:tab" element={<ProjectGantt />} />
                  <Route path=":tab" element={<ProjectGantt />} />
                </Route>
                <Route path=":templateId" element={<ProjectTemplates />} />
              </Route>

              {/* Project */}
              <Route path="/projects">
                <Route path=":projectFilterType/:viewType/:projectId" element={<TasksContainer />}>
                  <Route path="" element={<TasksContainer />}>
                    <Route path=":sprintId" element={<TasksContainer />} />
                    <Route path=":sprintId/:tab" element={<TasksContainer />} />
                    <Route path=":sprintId/:taskId/:tab" element={<TasksContainer />} />
                  </Route>
                </Route>

                <Route path=":filterType">
                  <Route path="" element={<Projects />} />
                  <Route path="project-details/:projectId/:tab" element={<Projects />} />

                  {/* Gantt */}
                  <Route path="gantt/:projectId" element={<ProjectGantt />}>
                    <Route path="" element={<ProjectGantt />} />
                    <Route path=":taskId/:tab" element={<ProjectGantt />} />
                    <Route path=":tab" element={<ProjectGantt />} />
                  </Route>
                </Route>
              </Route>

              {/* Process instances - lists and tree */}
              <Route path="/process-instances/:filterType">
                <Route path="" element={<ProcessInstances />} />
                <Route
                  path=":templateId/process-instance-tree/:instanceId"
                  element={<ProcessInstanceTree />}
                >
                  <Route path=":mode" element={<ProcessInstanceTree />} />
                  <Route path=":mode/:taskId/:tab" element={<ProcessInstanceTree />} />
                  <Route path=":mode/:taskId" element={<ProcessInstanceTree />} />
                </Route>
                <Route path=":templateId" element={<ProcessInstances />}>
                  <Route path=":panelMode" element={<ProcessInstances />} />
                  <Route path=":panelMode/:instanceId/:panelTab" element={<ProcessInstances />} />
                </Route>
              </Route>

              {/* Templates */}
              <Route path="/process-templates/:viewType">
                <Route path="" element={<ProcessTemplates />} />
                <Route path=":selectedGroupId" element={<ProcessTemplates />} />
                <Route
                  path=":selectedGroupId/:templateType/:templateId"
                  element={<ProcessTemplates />}
                />
              </Route>

              {/* Desinger */}
              <Route path="/process-designer/:templateId/:version" element={<ProcessDesigner />}>
                <Route path=":stepId" element={<ProcessDesigner />} />
              </Route>

              {/* Analytics */}
              <Route path="/pages" element={<Analytics location={1} />}>
                <Route path=":id" element={<Analytics location={1} />} />
              </Route>

              {/* Administration */}
              <Route path="/administration" element={<Administration />}>
                <Route path=":group" element={<Administration />}>
                  <Route path=":type" element={<Administration />} />
                  <Route path=":type/:id" element={<Administration />} />
                  <Route path=":type/:id/:mode" element={<Administration />} />
                  <Route path=":type/:id/:mode/:tab" element={<Administration />} />
                </Route>
              </Route>

              {/* Tags */}
              <Route path="/tags" element={<Tags />} />
              <Route path="/tags/:tagId" element={<TagSearch />}>
                <Route path="task/:taskId/:tab" element={<TagSearch />} />
                <Route path="project/:projectId/:tab" element={<TagSearch />} />
                <Route path=":panelType/:panelId/:tab" element={<TagSearch />} />
              </Route>

              {/* Administration */}
              <Route path="/administration" element={<Administration />}>
                <Route path=":group" element={<Administration />}>
                  <Route path=":type" element={<Administration />} />
                  <Route path=":type/:id" element={<Administration />} />
                  <Route path=":type/:id/:mode" element={<Administration />} />
                  <Route path=":type/:id/:mode/:tab" element={<Administration />} />
                </Route>
              </Route>

              <Route path="/__DEBUG__" element={<Debug />} />

              <Route path="/:notFound" component={NotFound} />
            </Routes>
          </ProjectProvider>

          {showNotificationsPanel ? notificationsPanel : null}
          {showReleaseNotesPanel ? releaseNotesPanel : null}
          <TrainerPanel
            isOpen={showTrainerPanel}
            setIsOpen={setShowTrainerPanel}
            onDismiss={onToggleTrainerPanel}
          />
        </Suspense>
      </ContentWrapperStyled>
    </PageWrapper>
  );
}

Routing.propTypes = {
  logout: PropTypes.func.isRequired,
  contentToShow: PropTypes.shape({
    tasks: PropTypes.bool,
    processes: PropTypes.bool
  }).isRequired
};

export default Routing;
