import styled from 'styled-components';
import { checkScreenWidth } from 'utils/helpers';

export const HeaderButtonsRowStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: fit-content;

  .c-profile-persona {
    max-width: ${checkScreenWidth('extraSmall') ? '60px' : '100%'};
  }
`;

export const Divider = styled.div`
  width: 1px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: ${({ backgroundColor }) => backgroundColor || 'rgba(255, 255, 255, 0.3)'};
  height: 80%;
`;
