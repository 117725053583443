import { Dialog, SmallTextField } from 'components/';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest from 'services/api';
import { useTheme } from 'styled-components';

function CreateCountryDialog({ hidden, onCancel, onConfirm, country }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [newCountry, setNewCountry] = useState(country);
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true);

  useEffect(() => {
    if (!newCountry) {
      setNewCountry(country);
    }
  }, [newCountry, country]);

  useEffect(() => {
    if (newCountry) {
      const { name, code } = newCountry;

      setIsPrimaryButtonDisabled(!name || !code);
    }
  }, [newCountry]);

  function close() {
    onCancel();
  }

  async function saveCountry(closeDialog) {
    setIsPrimaryButtonDisabled(true);

    const body = JSON.stringify(newCountry);
    let method = 'POST';
    if (newCountry.id) {
      method = 'PUT';
    }

    let error;

    const countryFromDB = await fetchRequest({ url: `Country`, method, body });

    if (!error) {
      setNewCountry(countryFromDB);
      if (newCountry.id) {
        onConfirm(newCountry, closeDialog);
      } else {
        onConfirm(countryFromDB, closeDialog);
      }
    }
  }

  async function handleKeyPress(e) {
    if (e.key === 'Enter' && newCountry && newCountry.name && newCountry.code) {
      saveCountry();
    }
  }

  function handleName(value) {
    const tempCountry = { ...newCountry, name: value };
    setNewCountry(tempCountry);
  }

  function handleCode(value) {
    const tempCountry = { ...newCountry, code: value };
    setNewCountry(tempCountry);
  }

  const textFieldStyles = {
    fieldGroup: [
      {
        border: `1px solid rgb(${theme.dynamicField.outline})`,
        borderRadius: 3,
        ':after': { borderRadius: 3 }
      }
    ],
    field: { color: `rgb(${theme.dynamicField.fieldForeground})` }
  };

  return (
    <Dialog
      hidden={hidden}
      onDismiss={close}
      styles={{
        main: {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '450px',
              minHeight: '200px'
            }
          }
        }
      }}
      title={country?.id ? t('createCountry.dialog.titleEdit') : t('createCountry.dialog.title')}
      primaryButtonProps={{
        text: t('createRole.dialog.button.saveAndClose'),
        onClick: () => saveCountry(true),
        disabled: isPrimaryButtonDisabled,
        split: true,
        menuProps: {
          items: [
            {
              key: 'saveAndClose',
              text: t('createRole.dialog.button.save'),
              onClick: () => saveCountry(false)
            }
          ]
        }
      }}
      defaultButtonProps={{
        text: t('createCountry.dialog.button.cancel'),
        onClick: close
      }}
      content={
        <div style={{ paddingTop: 8 }}>
          <SmallTextField
            label={t('createCountry.dialog.name.label')}
            required
            autoComplete="off"
            defaultValue={newCountry?.name}
            onChange={(value) => handleName(value)}
            onKeyPress={handleKeyPress}
            autoFocus
            styles={textFieldStyles}
          />
          <SmallTextField
            label={t('createCountry.dialog.code.label')}
            required
            defaultValue={newCountry?.code}
            onChange={(value) => handleCode(value)}
            onKeyPress={handleKeyPress}
            styles={textFieldStyles}
          />
        </div>
      }
    />
  );
}

CreateCountryDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  country: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string
  })
};

CreateCountryDialog.defaultProps = {
  hidden: true,
  country: null
};

export default CreateCountryDialog;
