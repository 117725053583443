import { Dialog, LoadingSpinner, RichTextEditor } from 'components';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiErrorHandler } from 'services/api';
import { createTask } from 'services/fetchRequests';
import styled from 'styled-components';
import { useNotificationContext } from 'features/App/context/NotificationContext';
import { MessageBar, MessageBarType, TextField } from '@fluentui/react';
import { AppContext } from 'features/App';

const RteWrapper = styled.div`
  margin-top: 10px;

  .rte-focused,
  .rte-base {
    position: relative;
    min-height: 32px;
  }

  .rte-focused {
    border: 1px solid transparent;

    &:after {
      border: ${(props) => `2px solid ${props.theme.themePrimary}`};
      border-radius: 3px;
      bottom: -1px;
      content: '';
      left: -1px;
      pointer-events: none;
      position: absolute;
      right: -1px;
      top: -1px;
    }
  }

  .rte-base {
    border: 1px solid #acabaa;
    border-radius: 3px;

    &:hover {
      border: 1px solid transparent;
      border-radius: 3px;
      &:after {
        border: 1px solid black;
        border-radius: 3px;
        bottom: -1px;
        content: '';
        left: -1px;
        pointer-events: none;
        position: absolute;
        right: -1px;
        top: -1px;
      }
    }
  }

  .rte-disabled {
    background-color: #f3f2f1;
    position: relative;
    min-height: 32px;
    border: 1px solid #a19f9d;
    border-radius: 3px;
    color: #a19f9d;

    .DraftEditor-editorContainer * {
      color: #323130;
    }

    .public-DraftEditorPlaceholder-root * {
      color: #a19f9d;
    }
  }
`;

function DeleteAbortInstanceDialog({ abortInstance, deleteInstance, type, instance, onDismiss }) {
  const { t } = useTranslation();

  const {
    globalAppState: { currentUser }
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [dialogType, setDialogType] = useState(type);
  const [requestProps, setRequestProps] = useState(null);

  const abortReasonWillBeRequired = instance?.creator?.userId === currentUser?.userId;
  const [showReasonRequiredError, setShowReasonRequiredError] = useState(false);
  const [reason, setReason] = useState('');

  useEffect(() => {
    setDialogType(type);
    setShowReasonRequiredError(false);
    setReason('');
  }, [type]);

  function sendRequest() {
    if (requestProps) {
      const request = {
        title: t('processInstances.dialog.request.taskTitle', {
          instanceName: requestProps.instance.name,
          context: type
        }),
        typeId: 2,
        priority: 5,
        startDate: new Date(),
        // due date is today + 7 days
        dueDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
        assignedToTeam: requestProps.instance.team,
        description: requestProps.description
      };

      createTask(request);
      onDialogDismiss();
    }
  }

  function getDialogContent() {
    if (!dialogType) return null;

    let content = null;

    if (loading) {
      content = (
        <div style={{ margin: 10 }}>
          <LoadingSpinner label={t('globals.loading', { label: '' })} />
        </div>
      );
    } else if (dialogType === 'abort') {
      content = (
        <div>
          {t('processInstances.dialog.abort.message')}

          <TextField
            label={t('processInstances.dialog.abort.reason')}
            placeholder={t('processInstances.dialog.abort.reasonPlaceholder')}
            autoFocus
            required={showReasonRequiredError || abortReasonWillBeRequired}
            multiline
            value={reason}
            onChange={(_, value) => setReason(value)}
          />

          {showReasonRequiredError && (
            <MessageBar messageBarType={MessageBarType.error}>
              {t('processInstances.dialog.abort.reasonIsRequired')}
            </MessageBar>
          )}
        </div>
      );
    } else if (dialogType === 'delete') {
      content = t('processInstances.dialog.delete.message');
    } else if (dialogType === 'request') {
      content = (
        <div>
          <div>
            <div>
              {t('processInstances.dialog.request.content', { instance: requestProps.instance })}
            </div>
            <div style={{ marginTop: 5 }}>
              {t('processInstances.dialog.request.content2', { instance: requestProps.instance })}
            </div>
          </div>
          <RteWrapper>
            <RichTextEditor
              disabled={false}
              displayCmdBar
              label={t('processInstances.dialog.request.reason')}
              onChange={({ value }) => {
                let valueToProvide = value;

                if (valueToProvide === '<p><br></p>') {
                  valueToProvide = null;
                }

                const hint = `${t('processInstances.dialog.request.mainProcess')}: ${
                  requestProps.definition.name
                } > <a target="_blank" href="${window.location.href}/process-instances/all/${
                  requestProps.definition.id
                }/process-instance-tree/${requestProps.instance.id}">${
                  requestProps.instance.name
                }</a><p>${t('processInstances.dialog.request.process')}: ${
                  instance.routeDefinitionName
                } > <a target="_blank" href=${window.location.href}"/process-instances/all/${
                  instance.routeDefinitionId
                }/process-instance-tree/${instance.id}">${instance.name}</a><p><br>${t(
                  'processInstances.dialog.request.hintLabel'
                )}:<br>${t('processInstances.dialog.request.hint')}<br><br>${t(
                  'processInstances.dialog.request.reason'
                )}:`;

                if (valueToProvide) {
                  valueToProvide = `<p>${hint}${valueToProvide}</p>`;
                }

                setRequestProps((prevState) => ({ ...prevState, description: valueToProvide }));
              }}
              required
              styles={{
                editorWrapper: { padding: 7 }
              }}
            />
          </RteWrapper>
        </div>
      );
    }

    return <div style={{ marginTop: 10, minWidth: 510 }}>{content}</div>;
  }

  function getDialogTitle() {
    if (!dialogType) return null;

    if (dialogType === 'abort') {
      return t('processInstances.dialog.abort.title');
    }

    if (dialogType === 'delete') {
      return t('processInstances.dialog.delete.title');
    }

    if (dialogType === 'request') {
      return t('requestPanelNewEdit.commandBar.button.sendRequest');
    }

    return null;
  }

  function getPrimaryButtonLabel() {
    if (!dialogType) return null;

    if (dialogType === 'abort') {
      return t('processInstances.dialog.abort.primaryButton');
    }

    if (dialogType === 'delete') {
      return t('processInstances.dialog.delete.primaryButton');
    }

    if (dialogType === 'request') {
      return t('requestPanelNewEdit.commandBar.button.sendRequest');
    }

    return null;
  }

  function onDialogDismiss() {
    setDialogType(null);
    setRequestProps(null);
    setLoading(false);
    onDismiss();
  }

  const { showError } = useNotificationContext();

  function onPrimaryButtonClick() {
    if (!dialogType) return null;

    if (dialogType === 'abort') {
      setLoading(true);

      abortInstance(instance, reason)
        .then(onDialogDismiss)
        .catch(async (errorPromise) => {
          setLoading(false);
          setRequestProps(null);

          const errorJSON = await errorPromise;
          if (errorJSON.status === 400) {
            let result = errorJSON.message;
            // FIXME: jank
            if (result.startsWith('{')) {
              result = JSON.parse(errorJSON.message);
            }
            if (result && typeof result === 'object' && 'definition' in result) {
              const { definition } = result;
              const mainInstance = result.instance;

              setRequestProps({ definition, instance: mainInstance });
              setDialogType('request');
            } else {
              // probably because reason is required
              setShowReasonRequiredError(true);
            }
          } else {
            showError(errorPromise);
          }
        });
    }

    if (dialogType === 'delete') {
      setLoading(true);

      deleteInstance(instance)
        .then(onDialogDismiss)
        .catch(async (errorPromise) => {
          setLoading(false);

          const errorJSON = await errorPromise;
          if (errorJSON.status === 400) {
            const result = JSON.parse(errorJSON.message);
            const { definition } = result;
            const mainInstance = result.instance;

            setRequestProps({ definition, instance: mainInstance });
            setDialogType('request');
          } else {
            apiErrorHandler(errorPromise);
          }
        });
    }

    if (dialogType === 'request') {
      sendRequest();
    }

    return null;
  }

  if (!dialogType) return null;

  const disableButtonBecauseOfAbortReason =
    dialogType === 'abort' && abortReasonWillBeRequired && !reason;

  return (
    <Dialog
      defaultButtonProps={{ onClick: onDialogDismiss, text: t('globals.cancel') }}
      primaryButtonProps={{
        disabled:
          loading ||
          (requestProps && !requestProps.description) ||
          disableButtonBecauseOfAbortReason,
        onClick: onPrimaryButtonClick,
        text: getPrimaryButtonLabel()
      }}
      content={getDialogContent()}
      hidden={false}
      onDismiss={onDialogDismiss}
      styles={{
        main: { minWidth: '550px !important', maxWidth: '30vw !important', borderRadius: '5px' }
      }}
      title={getDialogTitle()}
    />
  );
}

export default DeleteAbortInstanceDialog;

DeleteAbortInstanceDialog.propTypes = {
  abortInstance: PropTypes.func.isRequired,
  type: PropTypes.string,
  deleteInstance: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  instance: PropTypes.object
};
