import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled, { useTheme } from 'styled-components';
import { instancePropType, RouteInstanceStatus } from 'types';
import { IconButton } from '@fluentui/react';

const NameColumn = styled.div`
  display: flex;
  justify-content: space-between;

  .c-details-list__name {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function RouteInstancesListNameCell({
  onDeleteInstance,
  onAbortInstance,
  instance,
  onOpenInstancePanel
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { filterType, templateId } = useParams();

  function onDownloadExcelFile(file) {
    const downloadLink = document.createElement('a');
    fetchRequest({
      url: `/Route/Instance/Excel/${file.id}`,
      ignoreContentType: true
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          return navigator.msSaveOrOpenBlob(blob, 'data.xlsx');
        }
        return URL.createObjectURL(blob);
      })
      .then((blobUrl) => {
        downloadLink.href = blobUrl;
        downloadLink.download = 'data.xlsx';
        document.body.appendChild(downloadLink);
        if (typeof window.navigator.msSaveBlob === 'undefined') {
          downloadLink.click();
        }
        URL.revokeObjectURL(blobUrl);
      })
      .catch(apiErrorHandler);
  }

  const menuItems = [];

  if (instance.permissions.readSummary) {
    menuItems.push({
      key: 'instancePanel',
      text: t('routeInstances.detailList.props.panel'),
      iconProps: { iconName: 'SidePanelMirrored' },
      onClick: () => onOpenInstancePanel(instance)
    });
  }

  const isAbortedOrCompleted =
    instance.status === RouteInstanceStatus.AbortedByUser ||
    instance.status === RouteInstanceStatus.AbortedBySubProcess ||
    instance.status === RouteInstanceStatus.Completed;

  if (instance.permissions.abort && !isAbortedOrCompleted) {
    menuItems.push({
      key: 'instanceAbort',
      text: t('globals.abort'),
      iconProps: { iconName: 'Cancel' },
      onClick: () => onAbortInstance(instance)
    });
  }

  if (instance.permissions.delete) {
    menuItems.push({
      key: 'instanceDelete',
      text: t('globals.delete'),
      iconProps: { iconName: 'Delete' },
      onClick: () => onDeleteInstance(instance)
    });
  }

  if (instance.permissions.readExcel) {
    menuItems.push({
      key: 'excel',
      text: t('routeInstances.detailList.excelExport'),
      iconProps: {
        className: 'ms-BrandIcon--icon16 ms-BrandIcon--excel',
        imageProps: {}
      },
      className: 'checklist-more-subitem',
      onClick: () => onDownloadExcelFile(instance)
    });
  }

  return (
    <NameColumn>
      <Link
        className="c-details-list__name"
        to={`/process-instances/${filterType}/${templateId}/process-instance-tree/${instance.id}`}
      >
        {instance.name}
      </Link>
      {!!menuItems.length && (
        <IconButton
          styles={{
            rootHovered: { backgroundColor: `rgb(${theme.detailsList.moreButtonHoverBackground})` },
            root: {
              height: '20px',
              float: 'right'
            },
            menuIcon: {
              fontSize: '14px',
              fontWeight: '600'
            }
          }}
          className="c-details-list__more-button"
          menuIconProps={{ iconName: 'More' }}
          menuProps={{
            items: menuItems
          }}
        />
      )}
    </NameColumn>
  );
}

RouteInstancesListNameCell.propTypes = {
  instance: instancePropType.isRequired,
  onOpenInstancePanel: PropTypes.func.isRequired,
  onDeleteInstance: PropTypes.func.isRequired,
  onAbortInstance: PropTypes.func.isRequired
};

export default RouteInstancesListNameCell;
