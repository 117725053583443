import TagPicker, { ITagPickerProps } from 'components/inputs/TagPicker';
import moment from 'moment';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFileProps, ITagProps } from 'types';
import {
  ActionButton,
  DocumentCardActions,
  DocumentCardActivity,
  DocumentCardDetails,
  DocumentCardPreview,
  DocumentCardType,
  DocumentCard as FluentDocumentCard,
  IButtonProps,
  IButtonStyles,
  IContextualMenuItem,
  IconButton,
  Image,
  Modal
} from '@fluentui/react';
import { useTheme } from 'styled-components';
import Editor, { IEditorValueProps } from './Editor';

export interface IDocumentCardProps {
  disabled?: boolean;
  additionalMenuItems?: (
    file: IFileProps,
    onFileUpdate?: IDocumentCardProps['onFileUpdate']
  ) => IContextualMenuItem[];
  documentFile: IFileProps;
  onDeleteFile: () => void;
  onDownload: ({ id }: { id: string }) => Promise<Response>;
  onSaveComment: (file: IFileProps, description?: string) => void;
  onFileUpdate: React.Dispatch<React.SetStateAction<IFileProps[] | null>>;
  onTagsSearch: ITagPickerProps['onTagsSearch'];
  onTagClick: ITagPickerProps['onTagClick'];
  onTagChange: (file: IFileProps, tags: ITagProps[]) => void;
  onOpenWopi?: (fileId: string) => void;
}

function DocumentCard({
  disabled,
  documentFile,
  onDeleteFile,
  onSaveComment,
  onDownload,
  additionalMenuItems,
  onTagsSearch,
  onTagClick,
  onFileUpdate,
  onTagChange,
  onOpenWopi
}: IDocumentCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [displayCompleteMessage, setDisplayCompleteMessage] = useState({
    showMoreButton: false,
    fullMessage: false
  });

  const [displayTagPicker, setDisplayTagPicker] = useState(false);

  // whenever text can be edited
  const [editModeActive, setEditModeActive] = useState(false);

  // ref to determine message height
  const rteWrapperRef = useRef<HTMLDivElement>(null);

  // url to display image previews
  const [fileImageBlobUrl, setFileImageBlobUrl] = useState<string | null>(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

  // set fileImageBlobUrl before initialization
  const [initialized, setInitialized] = useState(false);

  // maximum height should be around 5 lines
  const maxMessageHeight = 50;

  useLayoutEffect(() => {
    if (rteWrapperRef.current) {
      setTimeout(() => {
        // time out because the rte needs to initialize
        const element = rteWrapperRef.current;

        if (element) {
          const { scrollHeight, offsetHeight } = element;

          setDisplayCompleteMessage((prevState) => ({
            ...prevState,
            showMoreButton: scrollHeight > offsetHeight
          }));
        }
      }, 1);
    }
  }, [rteWrapperRef]);

  const isImage = useCallback((text?: string) => {
    if (!text) return false;

    return /.png|.jpg|.jpeg|.gif|.tiff|.tif/i.test(text);
  }, []);

  useEffect(() => {
    return () => {
      if (fileImageBlobUrl) {
        window.URL.revokeObjectURL(fileImageBlobUrl);
      }
    };
  }, [fileImageBlobUrl]);

  useEffect(() => {
    const setImageBlobUrl = async () => {
      const fileContents = await onDownload({ id: documentFile.id || '' });

      const blob = await fileContents.blob();
      const blobUrl = URL.createObjectURL(blob);

      setFileImageBlobUrl(blobUrl);
    };

    if (!initialized && isImage(documentFile.name) && documentFile.id) {
      setImageBlobUrl();

      setInitialized(true);
    } else {
      setInitialized(true);
    }
  }, [documentFile.id, documentFile.name, initialized, isImage, onDownload]);

  function handleSaveComment(editorValue: IEditorValueProps) {
    setEditModeActive(false);

    onSaveComment(documentFile, editorValue?.message || undefined);
  }

  function handleTagChange(tags: ITagProps[]) {
    onTagChange(documentFile, tags);
  }

  function onShowMoreButtonClick() {
    setDisplayCompleteMessage((prevState) => ({
      fullMessage: !prevState.fullMessage,
      showMoreButton: true
    }));
  }

  function onEditButtonClick() {
    setEditModeActive(true);

    if (!displayCompleteMessage.fullMessage) {
      setDisplayCompleteMessage({
        fullMessage: true,
        showMoreButton: false
      });
    }
  }

  function renderCommentMessage() {
    const { fullMessage } = displayCompleteMessage;

    const maxHeight = fullMessage ? 'max-content' : maxMessageHeight;

    return (
      <div ref={rteWrapperRef} style={{ maxHeight, overflow: 'hidden' }}>
        <Editor
          comment={documentFile.description}
          disabled={!editModeActive}
          displayCmdBar={editModeActive}
          editMode={editModeActive}
          onCancelEditMode={() => setEditModeActive(false)}
          onSaveComment={handleSaveComment}
          onTagClick={onTagClick}
          placeholder={t('conversation.comment.editor.placeholder')}
        />
      </div>
    );
  }

  function renderShowMoreButton() {
    const { fullMessage, showMoreButton } = displayCompleteMessage;

    if (showMoreButton) {
      return (
        <ActionButton
          styles={{
            root: {
              borderTop: !fullMessage
                ? `1px solid rgba(${theme.attachments.readMoreBorder} / ${theme.attachments.readMoreBorderOpacity})`
                : undefined,
              boxShadow: !fullMessage ? theme.attachments.readMoreShadow : undefined,
              zIndex: 10,
              width: '100%',
              height: 25,
              fontSize: 12,
              padding: 0
            },
            rootHovered: { textDecoration: 'underline' },
            label: { color: `rgb(${theme.attachments.readMoreForeground})`, margin: 0 }
          }}
          onClick={onShowMoreButtonClick}
        >
          {fullMessage
            ? t('conversation.comment.showMoreButton.showLess')
            : t('conversation.comment.showMoreButton.showMore')}
        </ActionButton>
      );
    }

    return null;
  }

  function onDownloadFile(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const downloadLink = document.createElement('a');

    if (documentFile.id) {
      onDownload({ id: documentFile.id })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((blobUrl) => {
          downloadLink.href = blobUrl;
          downloadLink.download = documentFile.name || '';

          document.body.appendChild(downloadLink);
          downloadLink.click();

          URL.revokeObjectURL(blobUrl);
        });
    }
  }

  function getFilepreviewIconProps(): { fileIconName: string; color: string } {
    const defaultColor = `rgb(${theme.attachments.documentPreviewIconForeground})`;
    const { extension } = documentFile;

    if (extension === 'docx') {
      return {
        fileIconName: 'WordDocument',
        color: `rgb(${theme.attachments.documentPreviewIconColorMSWord})`
      };
    }

    if (extension === 'pptx') {
      return {
        fileIconName: 'PowerPointDocument',
        color: `rgb(${theme.attachments.documentPreviewIconColorMSPowerPoint})`
      };
    }

    if (extension === 'xlsx') {
      return {
        fileIconName: 'ExcelDocument',
        color: `rgb(${theme.attachments.documentPreviewIconColorMSExcel})`
      };
    }

    if (extension === 'txt') {
      return { fileIconName: 'TextDocument', color: defaultColor };
    }

    if (extension === 'pdf') {
      return { fileIconName: 'PDF', color: defaultColor };
    }

    const isImage = /png|jpg|jpeg|gif|tiff|tif/i.test(extension || '');

    if (isImage) {
      return { fileIconName: 'FileImage', color: defaultColor };
    }

    const isVideo = /mp4|mov|wmv|avi|avchd|flv|f4v|swf/i.test(extension || '');

    if (isVideo) {
      return { fileIconName: 'Video', color: defaultColor };
    }

    return { fileIconName: 'Document', color: defaultColor };
  }

  function renderDescription(): JSX.Element | null {
    if (documentFile.description || editModeActive) {
      const padding = editModeActive
        ? 0
        : `0px 16px ${displayCompleteMessage.showMoreButton ? '0px' : '10px'} 16px`;

      return (
        <div style={{ width: '100%', display: 'flex', boxSizing: 'border-box', padding }}>
          <div style={{ width: '100%', position: 'relative' }}>
            {renderCommentMessage()}
            {renderShowMoreButton()}
          </div>
        </div>
      );
    }

    return null;
  }

  function renderTagPicker(): JSX.Element | null {
    if (documentFile.tags?.length || displayTagPicker) {
      return (
        <div style={{ margin: 5 }}>
          <TagPicker
            disabled={false}
            onChange={handleTagChange}
            onTagClick={onTagClick}
            onTagsSearch={onTagsSearch}
            placeHolder={t('taskDetailsBody.tagPicker.placeholder')}
            selectedTags={documentFile.tags}
            defaultValue={documentFile.tags || []}
          />
        </div>
      );
    }

    return null;
  }

  function renderDocumentCardPreviewWopiOverlay() {
    if (!documentFile.wopiUrl || !documentFile.id) return null;

    if (!onOpenWopi) return null;

    return (
      <div
        aria-hidden="true"
        style={{
          zIndex: 10,
          cursor: 'pointer',
          position: 'absolute',
          width: 135,
          height: 70
        }}
        onClick={() => onOpenWopi(documentFile.id as string)}
      />
    );
  }

  function renderDocumentCardPreview(): JSX.Element | null {
    if (fileImageBlobUrl) {
      return (
        <div
          style={{
            width: 135,
            height: 70,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: `1px solid rgb(${theme.attachments.documentCardDivider})`
          }}
        >
          <img
            aria-hidden="true"
            alt="img"
            className="document-image-preview"
            src={fileImageBlobUrl}
            onClick={() => setIsImageModalOpen(true)}
          />
        </div>
      );
    }

    return (
      <>
        {renderDocumentCardPreviewWopiOverlay()}
        <DocumentCardPreview
          previewImages={[
            {
              previewIconProps: {
                iconName: getFilepreviewIconProps().fileIconName,
                styles: {
                  root: {
                    fontSize: 38,
                    color: getFilepreviewIconProps().color,
                    backgroundColor: 'transparent'
                  }
                }
              },
              width: 135
            }
          ]}
          styles={{
            previewIcon: {
              backgroundColor: `rgb(${theme.attachments.documentPreviewIconBackground})`
            }
          }}
        />
      </>
    );
  }

  function renderDocumentsCardFarRightActions(): JSX.Element | null {
    let menuItems: IContextualMenuItem[] = [];

    if (additionalMenuItems) {
      menuItems = additionalMenuItems(documentFile, onFileUpdate);
    }

    if (documentFile.permissions?.delete || !documentFile.id) {
      menuItems.push({
        key: 'delete file',
        text: t('attachments.documentCard.deleteFile'),
        iconProps: { iconName: 'Delete' },
        onClick: onDeleteFile
      });
    }

    if (menuItems.length === 0) return null;

    return (
      <DocumentCardActions
        styles={{ action: { marginRight: '1px' }, root: { padding: '0px 12px' } }}
        actions={[
          {
            menuIconProps: { iconName: 'More', styles: { root: { fontSize: '17px' } } },
            menuProps: { items: menuItems }
          }
        ]}
      />
    );
  }

  function renderImagePreviewCloseButton(): JSX.Element {
    const ImagePreviewCloseButtonStyles: IButtonStyles = {
      root: {
        backgroundColor: 'rgb(207, 207, 207, 0.4)',
        color: 'rgb(50, 49, 48);',
        marginRight: 15,
        marginTop: 10,
        position: 'absolute',
        right: 0
      },
      rootHovered: { backgroundColor: 'rgb(207, 207, 207, 0.6)', color: 'rgb(32, 31, 30)' },
      rootPressed: { backgroundColor: 'rgb(189, 189, 189, 0.6)', color: 'rgb(32, 31, 30)' }
    };

    return (
      <IconButton
        iconProps={{ iconName: 'Cancel' }}
        onClick={() => setIsImageModalOpen(false)}
        styles={ImagePreviewCloseButtonStyles}
      />
    );
  }

  const documentCardActions: IButtonProps[] = [
    {
      iconProps: {
        iconName: 'Download',
        styles: {
          root: { cursor: !documentFile.id ? 'not-allowed !important' : 'pointer' }
        }
      },
      href: documentFile.downloadUrl,
      onClick: onDownloadFile,
      disabled: !documentFile.id,
      styles: { rootDisabled: { background: 'transparent', cursor: 'not-allowed' } },
      ariaLabel: t('globals.downloadFile'),
      title: t('globals.downloadFile')
    }
  ];

  if (onOpenWopi && documentFile.wopiUrl && documentFile.id) {
    documentCardActions.push({
      iconProps: { iconName: 'OpenInNewTab' },
      onClick: () => onOpenWopi(documentFile.id as string),
      ariaLabel: t('globals.openFile'),
      title: t('globals.openFile')
    });
  }

  if (!disabled) {
    documentCardActions.push({
      iconProps: { iconName: 'InsertTextBox' },
      onClick: () => onEditButtonClick(),
      ariaLabel: t('attachments.documentCard.addDescription'),
      title: t('attachments.documentCard.addDescription')
    });

    documentCardActions.push({
      iconProps: { iconName: 'Tag' },
      onClick: () => setDisplayTagPicker(true),
      ariaLabel: t('attachments.documentCard.tags'),
      title: t('attachments.documentCard.tags')
    });
  }

  return (
    <FluentDocumentCard
      className="ms-motion-fadeIn"
      styles={{
        root: {
          borderRadius: '4px',
          marginBottom: '10px',
          width: '100%',
          maxWidth: '100%',
          boxShadow: 'rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem'
        }
      }}
      type={DocumentCardType.normal}
    >
      <div style={{ display: 'flex' }}>
        {renderDocumentCardPreview()}

        <DocumentCardDetails
          styles={{
            root: {
              maxHeight: 70,
              borderBottom: `1px solid rgb(${theme.attachments.documentCardDivider})`
            }
          }}
        >
          <div
            style={{
              fontSize: '18px',
              fontWeight: 400,
              padding: '8px 16px',
              display: 'block',
              overflow: 'hidden',
              height: '38px',
              lineHeight: '18px',
              textOverflow: 'ellipsis',
              color: `rgb(${theme.attachments.documentTitleForeground})`
            }}
            title={documentFile.name}
          >
            {documentFile.name}
          </div>
          <DocumentCardActivity
            activity={moment(documentFile.creationDate).format('DD.MM.YYYY')}
            people={[
              {
                name: documentFile.creator?.name || '',
                profileImageSrc: documentFile.creator?.pictureUrl || ''
              }
            ]}
            styles={{ root: { padding: '4px 16px 6px 16px' } }}
          />
        </DocumentCardDetails>
      </div>
      {renderTagPicker()}
      {renderDescription()}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DocumentCardActions
          styles={{ action: { marginRight: '1px' }, root: { padding: '0px 12px' } }}
          actions={documentCardActions}
        />
        {renderDocumentsCardFarRightActions()}
      </div>
      <Modal
        isOpen={isImageModalOpen}
        onDismiss={() => setIsImageModalOpen(false)}
        isBlocking={false}
        styles={{ scrollableContent: { overflow: 'hidden' } }}
      >
        <div style={{ overflow: 'auto', maxHeight: '80vh', maxWidth: '80vh' }}>
          {renderImagePreviewCloseButton()}
          <Image
            shouldFadeIn={false}
            src={fileImageBlobUrl || ''}
            styles={{ image: { width: '100%', height: '100%', objectFit: 'cover' } }}
          />
        </div>
      </Modal>
    </FluentDocumentCard>
  );
}

export default DocumentCard;
