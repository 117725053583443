import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

export const WrapperStyled = styled.div`
  background-color: rgb(${({ theme }) => theme.administration.settingsBackground});
  overflow: hidden;
  height: 100%;
  display: flex;

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding-bottom: 50px;
  }
`;

export const ListWrapperStyled = styled.div`
  margin: 15px;
  padding: 5px;
  flex-basis: 1100px;
  overflow: hidden;
  display: flex;
`;

export const FieldRowStyled = styled.div`
  &:hover {
    .deleteButton {
      visibility: visible;
    }
  }
`;

export const TitleColumnStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleStyled = styled.div<{ $disabled?: boolean }>`
  color: ${(props) =>
    props.$disabled
      ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
      : `rgb(${props.theme.detailsList.linkNameForeground})`};

  &:hover {
    color: ${(props) =>
      props.$disabled
        ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
        : `rgb(${props.theme.detailsList.linkNameHoverForeground})`};
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  }
`;
