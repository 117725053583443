import { createGlobalStyle } from 'styled-components';
import { getThemeStylesAsCss, ThemeConfig } from '../../utils/theme';

const GlobalStyle = createGlobalStyle<{ themeConfig: ThemeConfig }>`
  :root {
    ${(props) => getThemeStylesAsCss(props.themeConfig)}
  }

  :where(*) {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;
    background: rgb(${({ theme }) => theme.app.background});
    color: rgb(${({ theme }) => theme.app.foreground});
  }

  html,
  body {
    height: 100%;
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: rgb(${({ theme }) => theme.app.anchorForeground});

    &:hover {
      text-decoration: underline;
    }
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 14px;
    margin: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(${({ theme }) => theme.app.scrollbarThumb});
    border-radius: 16px;
    background-clip: content-box;
    border: 4px solid transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(${({ theme }) => theme.app.scrollbarThumbHover});
  }

  :focus,
  :active {
    outline: 0 !important;
  }

  .ms-Fabric {
    /** this is hardcoded in fluent-ui for some reason */
    color: rgb(${({ theme }) => theme.extraFluentComponentStyles.fabricForeground});
  }
  
  .ms-Callout:not(.ms-TeachingBubble) {
    border-radius: 8px;
    overflow: hidden;
  }

  .ms-Callout {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.extraFluentComponentStyles.calloutShadow};
  }
  .ms-Callout,
  .ms-Callout-main,
  .ms-Callout-beakCurtain,
  .ms-Callout-main > .ms-ContextualMenu-container > .ms-ContextualMenu  {
    background: rgb(${({ theme }) => theme.extraFluentComponentStyles.calloutBackground});
  }

  .ms-ContextualMenu-list {
    padding: 4px;
  }

  .ms-ContextualMenu-link {
    overflow: hidden;
    border-radius: 4px;
  }

  .ms-OverflowSet-item {
    overflow: hidden;
    border-radius: 4px;
    height: auto;
    margin: 5px;
  }

  .ms-Dialog-title {
    background-color: rgb(${({ theme }) =>
      theme.extraFluentComponentStyles.dialogHeaderBackground});
    color: rgb(${({ theme }) => theme.extraFluentComponentStyles.dialogHeaderForeground});
  }

  .ms-Button.ms-Button--primary,
  .ms-Button.ms-Button--default,
  .ms-Button.ms-Button--icon,
  .ms-TextField-fieldGroup,
  .ms-Dropdown,
  .ms-Dropdown-title,
  .ms-ComboBox,
  .ms-BasePicker-text,
  .ms-SearchBox {
    border-radius: 0.5rem;
  }

  .ms-TextField-fieldGroup::after,
  .ms-Dropdown::after,
  .ms-Dropdown:focus::after,
  .ms-ComboBox::after,
  .ms-BasePicker-text::after,
  .ms-SearchBox::after {
    border-radius: inherit;
  }
`;

export default GlobalStyle;
