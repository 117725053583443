import { useEffect, useMemo } from 'react';
import { z } from 'zod';
import { DEFAULT_THEME_PREFERENCE, DeviceThemePreference } from '../utils/theme';
import { useApiDataCache, useApiObject } from './api2';
import { MEMBER_CLIENT_STORE } from './api2/types/clientStore';
import { fetchVoid } from '../services/api2';

const THEME_PREFERENCE_KEY = 'themePreference';

const ClientThemePreference = z.object({
  version: z.literal(1),
  colorScheme: z.nativeEnum(DeviceThemePreference)
});

type IClientThemePreference = z.infer<typeof ClientThemePreference>;

const CACHED_THEME_PREFERENCE_KEY = 'cachedEpThemePreference';
function getCachedThemePreference() {
  try {
    return ClientThemePreference.parse(
      JSON.parse(localStorage.getItem(CACHED_THEME_PREFERENCE_KEY))
    ).colorScheme;
  } catch {
    return DEFAULT_THEME_PREFERENCE;
  }
}

function setCachedThemePreference(data: IClientThemePreference) {
  localStorage.setItem(CACHED_THEME_PREFERENCE_KEY, JSON.stringify(data));
}

export default function useThemePreference(): {
  isLoading: boolean;
  error: Error | null;
  colorScheme: DeviceThemePreference;
  setColorScheme: (scheme: DeviceThemePreference) => Promise<void>;
} {
  const {
    isLoading,
    error: loadError,
    data,
    mutate
  } = useApiObject(MEMBER_CLIENT_STORE, THEME_PREFERENCE_KEY);

  const { error: parseError, value } = useMemo<{
    value: IClientThemePreference | null;
    error: Error | null;
  }>(() => {
    if (!data) return { value: null, error: null };
    try {
      const value = ClientThemePreference.parse(data.value);
      return { value, error: null };
    } catch (error) {
      return { value: null, error };
    }
  }, [data]);

  const error = loadError || parseError;

  const cache = useApiDataCache();

  return useMemo(
    () => ({
      isLoading,
      error,
      colorScheme: value?.colorScheme ?? getCachedThemePreference(),
      setColorScheme(colorScheme) {
        const newSettings: IClientThemePreference = {
          version: 1,
          colorScheme
        };
        setCachedThemePreference(newSettings);

        return mutate(
          fetchVoid({
            url: `tenants/${cache.tenantId}/members/${cache.memberId}/client-store/${THEME_PREFERENCE_KEY}`,
            method: 'PUT',
            body: JSON.stringify(newSettings)
          }).then(() => ({ value: newSettings })),
          { value: newSettings }
        ).promise.then(() => null);
      }
    }),
    [isLoading, error, value, mutate, cache]
  );
}

export function ThemePreferenceMixin({
  onSetColorScheme
}: {
  onSetColorScheme: (preference: DeviceThemePreference) => void;
}) {
  const { colorScheme } = useThemePreference();

  useEffect(() => {
    onSetColorScheme(colorScheme);
  }, [colorScheme, onSetColorScheme]);

  return null;
}
