import { formatStringHorizontal, getWidthOfText } from 'components/charts';
import PropTypes from 'prop-types';
import { getTheme } from '@fluentui/react';
import { useTheme } from 'styled-components';
import LogoEvocom from './LogoEvocom';

const folder = () => {
  const svgWidth = '113';
  const svgHeight = svgWidth / 1.41;
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="b"
          d={`M${svgWidth / 22.6} ${svgHeight / 20}h${svgWidth / 1.1}v${svgHeight / 1.67}H${
            svgWidth / 22.6
          }z`}
        />
        <filter
          x="-7.8%"
          y="-14.6%"
          width="115.5%"
          height="133.3%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
          <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1" />
        </filter>
        <linearGradient x1="11.759%" y1="25.579%" x2="82.973%" y2="77.11%" id="d">
          <stop stopOpacity="0" offset="0%" />
          <stop stopOpacity=".13" offset="86%" />
          <stop stopOpacity=".2" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={getTheme().palette.themePrimary}
          fillRule="nonzero"
          d={`M${svgWidth / 1.67} 0L${svgWidth / 2.02} ${svgHeight / 6.67}l${svgWidth / 9.42} ${
            svgHeight / 6.67
          }h${svgWidth / 2.57}V0z`}
        />
        <g>
          <use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <use fill="#FFF" xlinkHref="#b" />
        </g>
        <path
          fill={getTheme().palette.themePrimary}
          fillRule="nonzero"
          d={`M0 ${svgHeight}V${svgHeight / 6.67}h${svgWidth / 2.02}l${svgWidth / 9.42} ${
            svgHeight / 6.67
          }h${svgWidth / 2.57}v${svgHeight / 1.43}z`}
        />
        <path
          fill="url(#d)"
          fillRule="nonzero"
          d={`M0 ${svgHeight}V${svgWidth / 9.42}h${svgHeight / 1.43}l${svgWidth / 9.42} ${
            svgHeight / 6.67
          }h${svgWidth / 2.57}v${svgHeight / 1.43}z`}
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          opacity=".15"
          d={`M${svgWidth / 1.01} ${svgHeight / 3.33}v${svgHeight / 40}H${svgWidth / 1.69}L${
            svgWidth / 2.13
          } ${svgHeight / 6.67}h${svgWidth / 37.67}l${svgWidth / 9.35} ${svgHeight / 6.67}z`}
        />
      </g>
    </svg>
  );
};

export default folder;

export function IconFolder({ title, size }) {
  const theme = useTheme();

  const alternativeColor = '#A9A9A9';
  let iconBackgroundColor = `rgb(${theme.folderView.folderIconPrimary})`;
  let textColor = 'black';
  let status = 'enabled';
  if (size < 1) {
    iconBackgroundColor = alternativeColor;
    textColor = alternativeColor;
    status = 'disabled';
  }

  const svgWidth = '120';
  const svgHeight = '80';
  const folderWidth = '103';
  const fontSize = '13';
  const textX = folderWidth / 2 + 5;
  const textY = '115';
  const textWidth = folderWidth;
  const textHeight = 22;
  const rowDistance = '15';

  const stringWidth = getWidthOfText(title, 'Segoe UI', `${fontSize}px`);

  let formatedTitle = title;
  if (stringWidth > textWidth) {
    formatedTitle = formatStringHorizontal(
      title,
      textWidth,
      textHeight,
      textX,
      textY,
      rowDistance,
      fontSize
    );
  }

  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="b" d="M5 4h103v48H5z" />
        <filter
          x="-7.8%"
          y="-14.6%"
          width="115.5%"
          height="133.3%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
          <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1" />
        </filter>
        <linearGradient x1="11.759%" y1="25.579%" x2="82.973%" y2="77.11%" id="d">
          <stop stopOpacity="0" offset="0%" />
          <stop stopOpacity=".13" offset="86%" />
          <stop stopOpacity=".2" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" className={status}>
        <path fill={iconBackgroundColor} fillRule="nonzero" d="M68 0L56 12l12 12h44V0z" />
        <g>
          <use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <use fill="#FFF" xlinkHref="#b" />
        </g>
        <path fill={iconBackgroundColor} fillRule="nonzero" d="M0 80V12h56l12 12h44v56z" />
        <path fill="url(#d)" fillRule="nonzero" d="M0 80V12h56l12 12h44v56z" />
        <path fill="#FFF" fillRule="nonzero" opacity=".15" d="M112 24v2H67.08L53 12h3l12.08 12z" />
        <text
          fill={textColor}
          textAnchor="middle"
          x={textX}
          y={textY}
          width={textWidth}
          height={textHeight}
          fontSize={fontSize}
          className="name"
        >
          {formatedTitle}
        </text>
      </g>
    </svg>
  );
}
IconFolder.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number
};

export function IconArrow({ name, size }) {
  const maxWindowWidth = window.innerWidth;
  let fontSize = 16.8;
  let width = 600;
  if (maxWindowWidth < 600) {
    width = maxWindowWidth - maxWindowWidth / 20;
  }
  if (maxWindowWidth < 400) {
    fontSize = 11.2;
  }
  if (maxWindowWidth < 250) {
    fontSize = 9.8;
  }

  const height = 50;
  let iconBackgroundColor = getTheme().palette.themePrimary;
  const alternativeColor = '#A9A9A9';
  let status = 'enabled';
  if (size < 1) {
    iconBackgroundColor = alternativeColor;
    status = 'disabled';
  }

  const textWidth = width - width / 3.5;
  const textHeight = height - height / 1.5;

  const textX = width / 2;
  const textY = height / 1.7;
  const rowDistance = 10;

  const stringWidth = getWidthOfText(name, 'Segoe UI', `${fontSize}px`);

  let formatedTitle = name;
  if (stringWidth > textWidth) {
    formatedTitle = formatStringHorizontal(
      name,
      textWidth,
      textHeight,
      textX,
      textY,
      rowDistance,
      fontSize
    );
  }
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMinYMin meet"
      viewBox={`0 0 ${width} ${height}`}
      className={status}
    >
      <filter id="shadow" width="130%" height="130%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
        <feOffset dx="1.7" dy="1.7" result="offsetblur" />
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.8" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <g fill="none">
        <path
          d={`M0 0
          l${width - 20} 0
          l20 ${height / 2}
          l-20 ${height / 2}
          l-${width - 20} 0
          l20 -${height / 2}
          l-20 -${height / 2}`}
          fill={iconBackgroundColor}
        />
        <text fill={getTheme().palette.white}>
          <tspan
            textAnchor="middle"
            x={textX}
            y={textY}
            width={textWidth}
            height={textHeight}
            className="processTypeName"
          >
            {formatedTitle}
          </tspan>
          <tspan textAnchor="end" x={width - 15} y={textY} className="processCounter">
            {size.toString()}
          </tspan>
        </text>
      </g>
    </svg>
  );
}
IconArrow.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export function EvocomLogoLabel({ width, className, color }) {
  return (
    <div className={className} style={{ width }}>
      <LogoEvocom color={color} />
    </div>
  );
}

EvocomLogoLabel.propTypes = {
  width: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string
};

export function evocomBeeLogo(pixelSize, color) {
  return (
    <div style={{ width: pixelSize }}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 128 95.82"
      >
        <g id="Logo">
          <g>
            <g>
              <polygon
                fill={color || '#145AA9'}
                points="49.51,44.75 27.79,73.85 0,68.74 0,42.9 48.2,19.92 44.73,25.92 3.53,45.38 3.85,65.45 26.1,69.51 47.35,41.01"
              />
            </g>
            <g>
              <polygon
                fill={color || '#145AA9'}
                points="128,42.37 128,68.18 99.71,73.29 78.43,44.24 80.57,40.53 101.43,68.95 124.15,64.89 123.97,44.8 83.67,26 80.16,19.92"
              />
            </g>
            <g>
              <rect x="42.48" y="62.2" fill={color || '#145AA9'} width="43.31" height="7.9" />
            </g>
            <g>
              <rect x="57.48" y="87.92" fill={color || '#145AA9'} width="13.31" height="7.9" />
            </g>
            <g>
              <rect x="48.62" y="49.33" fill={color || '#145AA9'} width="31.03" height="7.9" />
            </g>
            <g>
              <rect x="48.62" y="75.06" fill={color || '#145AA9'} width="31.03" height="7.9" />
            </g>
            <g>
              <polygon
                fill={color || '#145AA9'}
                points="81.92,0.05 81.92,4.21 75.1,6.53 71.83,11.22 67.01,11.22 72.6,3.21"
              />
            </g>
            <g>
              <polygon
                fill={color || '#145AA9'}
                points="61.11,11.22 56.29,11.22 53,6.53 46.1,4.19 46.1,0 55.5,3.21"
              />
            </g>
            <polygon
              fill={color || '#145AA9'}
              points="78.14,30.82 71.14,42.96 57.13,42.96 50.12,30.82 57.13,18.7 71.14,18.7"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
