import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
  evaluateFieldGroup,
  getInstanceFieldValues,
  teamsSearch,
  userSearch
} from 'services/fetchRequests';
import { DefaultTheme } from 'styled-components';
import {
  IActivityProps,
  IBlobFile,
  IFieldGroupLinkProps,
  IFieldLinkProps,
  IFileProps,
  IFormGroupProps,
  ILongTextFieldAppendChangesProps,
  IOutboundFieldProps,
  ITaskProps,
  ITeamPersonaProps,
  ITeamProps,
  IUserPersonaProps,
  IUserProps,
  PageLocation,
  PersonFieldFormat,
  RequestStatus,
  RouteFieldType,
  TaskStatus,
  TaskType
} from 'types';
import { v4 as uuidv4 } from 'uuid';
import { IPersonaProps, IPivotStyles, getTheme } from '@fluentui/react';
import breakpoints from './breakpoints';

export function cloneObject<T>(src: T): T {
  return cloneDeep(src);
}

/**
 * Generate uuid
 *
 * @returns string
 */
export function generateUuid() {
  return uuidv4();
}

export function isUuid(value: string) {
  return value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
}

export const UUID_NULL = '00000000-0000-0000-0000-000000000000';
export const UUID_1 = '11111111-1111-1111-1111-111111111111';
export const UUID_F = 'ffffffff-ffff-ffff-ffff-ffffffffffff';

/**
 * Checks if windowsize is in range
 *
 * @param {array} ranges
 * @returns isInRange
 */
export function checkScreenWidth(ranges: string | string[]) {
  let size: string | null = null;

  const {
    largeMax,
    largeMin,
    mediumMax,
    mediumMin,
    smallMax,
    smallMin,
    extraSmallMin,
    extraSmallMax
  } = breakpoints;

  const mediaQuery = (minWidth: number, maxWidth: number) => {
    return window.matchMedia(`(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`).matches;
  };

  if (mediaQuery(extraSmallMin, extraSmallMax)) {
    // > 480px
    size = 'extraSmall';
  } else if (mediaQuery(smallMin, smallMax)) {
    // 481px - 767px
    size = 'small';
  } else if (mediaQuery(mediumMin, mediumMax)) {
    // 768px - 1024px
    size = 'medium';
  } else if (mediaQuery(largeMin, largeMax)) {
    // 1025px - 1280px
    size = 'large';
  } else {
    // < 1281px
    size = 'extraLarge';
  }

  return Array.isArray(ranges) ? ranges.findIndex((range) => range === size) > -1 : ranges === size;
}

export function convertEpUserOrTeamToPersonaProps(
  valueToConvert?: IUserProps | ITeamProps
): IUserPersonaProps | ITeamPersonaProps {
  let persona: IUserPersonaProps | ITeamPersonaProps;

  if (!valueToConvert) {
    return {
      text: '',
      secondaryText: '',
      showSecondaryText: false,
      id: '',
      imageUrl: '',
      title: ''
    };
  }

  if (isEpUserValue(valueToConvert)) {
    persona = {
      ...valueToConvert,
      text: valueToConvert.name || undefined,
      secondaryText: valueToConvert.email || undefined,
      showSecondaryText: !!valueToConvert.email,
      id: valueToConvert.userId || undefined,
      key: valueToConvert.userId || undefined,
      imageUrl: valueToConvert.pictureUrl || undefined
    };
  } else {
    const secondaryText = valueToConvert.displayTitle?.replace(valueToConvert?.title || '', '');

    persona = {
      ...valueToConvert,
      id: valueToConvert.id || '',
      key: valueToConvert.id || '',
      title: valueToConvert.title || '',
      secondaryText,
      showSecondaryText: !!secondaryText,
      name: valueToConvert.title || undefined,
      text: valueToConvert.title || undefined
    };
  }

  return persona;
}

export function convertPersonaIntoEpUser(persona?: IPersonaProps): IUserProps | undefined {
  if (!persona) return undefined;

  const { id, imageUrl, text, secondaryText } = persona;

  if (id) {
    return {
      email: secondaryText,
      name: text,
      pictureUrl: imageUrl,
      userId: id
    };
  }

  return undefined;
}

export function isEpUserValue(
  defaultValueToCheck: IUserProps | ITeamProps
): defaultValueToCheck is IUserProps {
  return !!(defaultValueToCheck as IUserProps)?.userId;
}

interface IDocumentFieldInformationProps {
  fieldGroupIndex?: number;
  fieldLink?: IFieldGroupLinkProps;
  fieldIndex: number;
  file?: File;
  fieldId: string;
  mediaValueIndex?: number;
  groupIndex: number;
}

export function findFilesToUploadInTaskGroups(groups: IFormGroupProps[]) {
  const documentFields: (IDocumentFieldInformationProps | null)[] = [];

  const getFile = ({
    fieldLink,
    fieldIndex,
    groupIndex,
    fieldGroupIndex
  }: {
    fieldLink: IFieldLinkProps;
    fieldIndex: number;
    groupIndex: number;
    fieldGroupIndex?: number;
  }) => {
    const routeFieldType = fieldLink?.field?.fieldType as RouteFieldType;

    // check if field is a document field
    if (
      routeFieldType === RouteFieldType.Document ||
      routeFieldType === RouteFieldType.Multimedia
    ) {
      const fieldLinkValue = fieldLink.value as IFileProps | IFileProps[];

      // check if a file is uploaded
      if (
        fieldLinkValue &&
        typeof fieldLinkValue === 'object' &&
        'blobFile' in fieldLinkValue &&
        fieldLinkValue.blobFile
      ) {
        documentFields.push({
          fieldGroupIndex,
          fieldId: fieldLink.field?.id || '',
          fieldIndex,
          file: fieldLinkValue.blobFile,
          groupIndex
        });
      }

      if (fieldLinkValue && Array.isArray(fieldLinkValue)) {
        fieldLinkValue.forEach((mediaValue, mediaValueIndex) => {
          if (
            mediaValue &&
            typeof mediaValue === 'object' &&
            'blobFile' in mediaValue &&
            mediaValue.blobFile
          ) {
            documentFields.push({
              fieldGroupIndex,
              fieldId: fieldLink.field?.id || '',
              fieldIndex,
              file: mediaValue.blobFile,
              groupIndex,
              mediaValueIndex
            });
          }
        });
      }
    }
  };

  if (groups?.length) {
    groups.forEach((group, groupIndex) => {
      group.fields.forEach((fieldLink, fieldIndex) =>
        getFile({ fieldLink, fieldIndex, groupIndex })
      );

      // find all fields and fieldGroups
      group.fields.forEach((fieldLink, fieldLinkIndex) => {
        if (fieldLink.fieldGroup) {
          fieldLink.fieldGroup?.fields?.forEach((fieldGroupFieldLink, fieldGroupIndex) => {
            getFile({
              fieldLink: fieldGroupFieldLink,
              fieldIndex: fieldLinkIndex,
              groupIndex,
              fieldGroupIndex
            });
          });
        }
      });
    });
  }

  return documentFields;
}

export function getSectionDocuments(fields?: IFieldLinkProps[]) {
  if (!fields) {
    return [];
  }

  return (
    fields
      // only file & fieldLinkId is needed
      .map((fieldLink) => {
        const file = fieldLink.value as IBlobFile;

        return {
          fieldType: fieldLink.field?.fieldType,
          file,
          fieldLinkId: fieldLink.id
        };
      })
      // filter all other fieldtypes
      .filter(
        ({ file, fieldType }) =>
          (fieldType === RouteFieldType.Multimedia || fieldType === RouteFieldType.Document) && file
      )
  );
}

export function prepareFieldGroupForEvaluation(fieldGroupLink: IFieldLinkProps) {
  const fieldGroupLinkClone = { ...fieldGroupLink };

  // delete file because its alredy stored in documents above and we do not want to send them to api
  if (fieldGroupLinkClone.fieldGroup?.fields) {
    fieldGroupLinkClone.fieldGroup.fields = fieldGroupLinkClone.fieldGroup?.fields?.map(
      (fieldLink) => {
        const fieldLinkClone = { ...fieldLink };

        if (fieldLinkClone.value && fieldLinkClone.field?.fieldType === RouteFieldType.Document) {
          // create new object without the file -> only url & text

          const value = fieldLinkClone.value as { text: string; url: string };

          const newValue = { text: value.text, url: value.url };

          fieldLinkClone.value = newValue;
        }

        return fieldLinkClone;
      }
    );
  }

  return fieldGroupLinkClone;
}

export function getFluentIconNameByFieldType(field?: {
  fieldType: RouteFieldType;
  connectUserAndTeam?: boolean;
  personFieldFormat?: PersonFieldFormat;
}) {
  if (!field?.fieldType) return '';

  const { fieldType } = field;

  if (fieldType === RouteFieldType.SmallText || fieldType === RouteFieldType.LongText) {
    return 'AlignLeft';
  }

  if (fieldType === RouteFieldType.Number) {
    return 'NumberSymbol';
  }

  if (fieldType === RouteFieldType.Boolean) {
    return 'DocumentApproval';
  }

  if (fieldType === RouteFieldType.DateTime) {
    return 'Calendar';
  }

  if (fieldType === RouteFieldType.Choice) {
    return 'CheckboxComposite';
  }

  if (fieldType === RouteFieldType.Person) {
    if (field.connectUserAndTeam || field.personFieldFormat === PersonFieldFormat.multiplePersons) {
      return 'People';
    }

    if (
      field.personFieldFormat === PersonFieldFormat.multipleTeams ||
      field.personFieldFormat === PersonFieldFormat.personsAndTeams ||
      field.personFieldFormat === PersonFieldFormat.singleTeam
    ) {
      return 'Group';
    }

    return 'Contact';
  }

  if (fieldType === RouteFieldType.Rating) {
    return 'FavoriteStarFill';
  }

  if (fieldType === RouteFieldType.Hyperlink) {
    return 'Link';
  }

  if (fieldType === RouteFieldType.Document) {
    return 'Attach';
  }

  if (fieldType === RouteFieldType.ExternalData) {
    return 'DatabaseSync';
  }

  if (fieldType === RouteFieldType.Lookup) {
    return 'LookupEntities';
  }

  if (fieldType === RouteFieldType.Location) {
    return 'LocationOutline';
  }

  if (fieldType === RouteFieldType.Signature) {
    return 'InsertSignatureLine';
  }

  if (fieldType === RouteFieldType.Scanner) {
    return 'GenericScan';
  }

  if (fieldType === RouteFieldType.Multimedia) {
    return 'PhotoVideoMedia';
  }

  if (fieldType === RouteFieldType.AI) {
    return 'WebAppBuilderFragment';
  }

  return '';
}

export function getDateStrings() {
  return {
    goToToday: i18next.t(`dateTime.datePicker.goToToday`),
    months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) =>
      i18next.t(`dateTime.datePicker.month`, { context: m.toString() })
    ),
    shortMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) =>
      i18next.t(`dateTime.datePicker.month`, { context: m.toString() }).substring(0, 3)
    ),
    days: [0, 1, 2, 3, 4, 5, 6].map((d) =>
      i18next.t(`dateTime.datePicker.day`, { context: d.toString() })
    ),
    shortDays: [0, 1, 2, 3, 4, 5, 6].map((d) => {
      return i18next.t(`dateTime.datePicker.day`, { context: d.toString() }).substring(0, 2);
    })
  };
}

export type ITaskPropsForType = Pick<
  ITaskProps,
  'typeId' | 'requestStatusId' | 'statusId' | 'routeInstanceId' | 'parentTaskId' | 'id'
>;

export function getTaskTypes(task: ITaskPropsForType) {
  const { typeId, requestStatusId, statusId, routeInstanceId } = task;

  const defaultId = '00000000-0000-0000-0000-000000000000';

  const isNewTask = !task.id;
  const isRequestTask = typeId === TaskType.Request;

  const isAcceptedRequestTask = isRequestTask && requestStatusId === RequestStatus.Accepted;
  const isApprovalChild = typeId === TaskType.Approval || typeId === TaskType.RouteApproval;
  const isApprovalParent =
    typeId === TaskType.ApprovalParent || typeId === TaskType.RouteApprovalParent;
  const isDataTask = typeId === TaskType.RouteData;
  const isDataParentTask = typeId === TaskType.RouteDataParent;
  const isParentTask =
    typeId !== TaskType.ProjectTemplateTask &&
    typeId !== TaskType.AgileProjectTask &&
    (!task.parentTaskId || task.parentTaskId === defaultId);
  const isApprovalTask = isApprovalChild || isApprovalParent;
  const isProcessTask = !!routeInstanceId && typeId && typeId > 6 && typeId !== 51 && typeId !== 50;
  const isRegularTask = typeId === TaskType.Task;
  const isBacklog = typeId === TaskType.AgileProjectBacklog;
  const isAgileProjectTask = typeId === TaskType.AgileProjectTask;
  const isClassicProjectTask = typeId === TaskType.ClassicProjectTask;
  const isClassicProjectMilestone = typeId === TaskType.ClassicProjectMilestone;

  const isTaskCompleted = statusId === TaskStatus.Completed || statusId === TaskStatus.Aborted;

  const isProcessDataSubTask = typeId === TaskType.RouteDataSub;
  const isRegularProcessTask = typeId === TaskType.RouteTask;

  const isSurveyProcessTask = typeId === TaskType.RouteSurvey;
  const isSurveyProcessParentTask = typeId === TaskType.RouteSurveyParent;
  const isApprovalProcessTask = typeId === TaskType.RouteApproval;
  const isCompletedSubTask =
    (statusId === TaskStatus.Aborted || task.statusId === TaskStatus.Completed) &&
    task.parentTaskId &&
    task.parentTaskId !== defaultId;

  const isTemplateTask =
    typeId === TaskType.ProjectTemplateMilestone || typeId === TaskType.ProjectTemplateTask;
  const isClassicTemplateMilestone = typeId === TaskType.ProjectTemplateMilestone;

  return {
    isAcceptedRequestTask,
    isApprovalTask,
    isApprovalParent,
    isApprovalChild,
    isDataTask,
    isParentTask,
    isBacklog,
    isTemplateTask,
    isProcessTask,
    isCompletedSubTask,
    isDataParentTask,
    isClassicTemplateMilestone,
    isAgileProjectTask,
    isClassicProjectMilestone,
    isRegularTask,
    isRequestTask,
    isClassicProjectTask,
    isTaskCompleted,
    isNewTask,
    isProcessDataSubTask,
    isRegularProcessTask,
    isSurveyProcessTask,
    isSurveyProcessParentTask,
    isApprovalProcessTask
  };
}

function createNumbersArray(from: number, length: number) {
  return Array.from(Array(length), (_, i) => i + from);
}

export function getActivityRedirectUrl(activity: IActivityProps) {
  const { type, newTask, newTeam, routeInstance, routeDefinition, newProject } = activity;

  if (newTask) {
    const taskDetailsTypes = [
      ...[16, 30, 43, 51, 52, 55, 57, 59, 61, 63, 65, 67, 69, 91, 152, 201],
      ...createNumbersArray(1, 7), // 1 - 7
      ...createNumbersArray(45, 3), // 45 - 48,
      ...createNumbersArray(70, 3), // 70 - 73,
      ...createNumbersArray(76, 4) // 76 - 79,
    ];

    if (taskDetailsTypes.includes(type)) {
      return `/tasks/all/${newTask.id}`;
    }

    if ([8, 9, 10, 84, 85].includes(type)) {
      return `/tasks/all/${newTask.id}/conversation`;
    }

    if ([...createNumbersArray(11, 7), 82, 83].includes(type)) {
      return `/tasks/all/${newTask.id}/checklist`;
    }

    if ([18, 19, 20].includes(type)) {
      return `/tasks/all/${newTask.id}/attachments`;
    }

    if ([30, 32].includes(type)) {
      return `/tasks/all/${newTask.id}`;
    }

    if ([129, 130, 131, 132, 133, 137, 138, 139, 140, 199].includes(type)) {
      const isClassic = [TaskType.ClassicProjectTask, TaskType.ClassicProjectMilestone].includes(
        newTask.typeId
      );

      return `/projects/all/kanban/${newTask.projectId}/${
        isClassic ? UUID_NULL : newTask.sprintId || UUID_NULL
      }/${newTask.id}/detail`;
    }
  }

  if (newTeam && [...createNumbersArray(21, 9)].includes(type) && type !== 26) {
    return `/teams/${newTeam.id}`;
  }

  if (routeDefinition && [35, 36].includes(type)) {
    return `/process-designer/${routeDefinition.id}/${routeDefinition.version}`;
  }

  if (routeInstance && [38, 39, 41, 42].includes(type)) {
    return `/process-instances/all/${routeInstance.routeDefinitionId}/process-instance-tree/${routeInstance.id}`;
  }
  if (routeInstance && [200].includes(type)) {
    return `/process-instances/all/${routeInstance.routeDefinitionId}/detail/${routeInstance.id}/instance`;
  }

  if (newProject) {
    if ([134, 135].includes(type)) {
      return `/projects/all/project-details/${newProject.id}/project`;
    }
  }

  return null;
}

export function extractHostAddressFromUrl(url: string) {
  let hostname;
  let protocol;
  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    [protocol, , hostname] = url.split('/');
  }

  if (protocol && hostname) {
    const hostAddress = `${protocol}//${hostname}`;

    return hostAddress;
  }

  return null;
}

export function getEmployeesTypes() {
  const employeesTypes = [
    {
      key: 0,
      text: i18next.t('helpers.employees.unknown')
    },
    {
      key: 1,
      text: '1 - 10'
    },
    {
      key: 2,
      text: '11 - 50'
    },
    {
      key: 3,
      text: '51 - 200'
    },
    {
      key: 4,
      text: '201 - 500'
    },
    {
      key: 5,
      text: '501 - 1000'
    },
    {
      key: 6,
      text: '1001 - 5000'
    },
    {
      key: 7,
      text: '5001 - 10000'
    },
    {
      key: 8,
      text: '10000+'
    }
  ];

  return employeesTypes;
}

function convertUserPropsToPersona(userToConvert: IUserProps): IPersonaProps {
  const { name, email, pictureUrl, userId } = userToConvert;
  return {
    ...userToConvert,
    text: name,
    secondaryText: email,
    imageUrl: pictureUrl,
    id: userId,
    showSecondaryText: true
  };
}

export function getTeamPoolPickerOption({
  filterText,
  secondaryText
}: {
  filterText?: string;
  secondaryText?: string;
}): IPersonaProps | null {
  if (!filterText) return null;

  const teamPoolString = 'TeamPool';
  const teamPoolOption = {
    key: teamPoolString,
    name: teamPoolString,
    login: teamPoolString,
    text: teamPoolString,
    showSecondaryText: !!secondaryText,
    secondaryText,
    userId: '00000000-0000-0000-0000-000000000000'
  };

  if (filterText === '***') return teamPoolOption;

  if (teamPoolString.toLowerCase().includes(filterText.toLowerCase())) return teamPoolOption;

  return null;
}

export function onCombinedPickerSearch(searchProps: {
  teamId?: string;
  filterText?: string;
  searchTeams?: boolean;
  searchUser?: boolean;
  type?: number;
  userId?: string;
  userId2?: string;
}) {
  const { teamId, filterText, searchTeams, searchUser, type, userId, userId2 } = searchProps;

  if (searchTeams && searchUser) {
    return Promise.all([
      teamsSearch({ searchTerm: filterText, userId: undefined }),
      userSearch({ searchTerm: filterText, type, teamId })
    ]).then(([teams, users]) => {
      return {
        consentNeeded: false,
        teams: teams.map(convertEpUserOrTeamToPersonaProps),
        users: users.items.map(convertUserPropsToPersona)
      };
    });
  }

  if (searchUser) {
    return userSearch({ searchTerm: filterText, type, teamId }).then((searchResult) => ({
      ...searchResult,
      users: searchResult.items.map(convertUserPropsToPersona),
      teams: []
    }));
  }

  if (searchTeams) {
    return teamsSearch({ searchTerm: filterText, userId, userId2 }).then((searchResult) => ({
      consentNeeded: false,
      teams: searchResult.map(convertEpUserOrTeamToPersonaProps),
      users: []
    }));
  }

  return Promise.resolve({ teams: [], users: [], consentNeeded: false });
}

export function getDefaultTheme() {
  const theme = {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff'
  };

  return theme;
}

/**
 * Checks if app is running within an iframe
 *
 * @return {boolean}
 */
export function isInIframe() {
  try {
    const force = localStorage.getItem('DEBUG_forceIframe');
    if (force === 'true') return true;
    if (force === 'false') return false;
  } catch {
    // ignore
  }

  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isInMsTeamsEnvironment() {
  try {
    const force = localStorage.getItem('DEBUG_forceMSTeams');
    if (force === 'true') return true;
    if (force === 'false') return false;
  } catch {
    // ignore
  }

  const msTeamsEnvironment = window.sessionStorage.getItem('msTeamsEnvironment');
  return !!msTeamsEnvironment;
}

export function adjustColor(color: string, amount: number) {
  return `#${color
    ?.replace(/^#/, '')
    ?.replace(/../g, (color) =>
      `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2)
    )}`;
}

/**
 * Calculate percentage for progress indicator
 *
 * @param {string} startDate
 * @param {string} endDate
 * @returns number
 */

/**
 * Format date
 *
 * @param {*} date
 * @param {string} format
 * @returns string
 */
export function formatDate(date: Date, format: string) {
  return moment(date).format(format);
}

/**
 * Format date
 * Returns localized date based on browser language
 *
 * @param {*} date
 * @returns string
 */
export function formatToLocalDateString(date: string | Date) {
  // Local culture setting for converting date in local date format
  const locale = window.navigator.language;

  const localDateString = new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  return localDateString;
}

/**
 * Validate date
 *
 * @param {string} string
 * @param {string} format
 * @param {bool} bool
 * @returns bool
 */
export function validateDate(string: string, format: string, bool: boolean) {
  return moment(string, format, bool).isValid();
}

/**
 * Convert to date
 *
 * @param {string} string
 * @param {string} format
 * @returns string
 */
export function convertStringToDate(string: string, format: string) {
  return moment(string, format).toDate();
}

/**
 * Format given date to german date format
 *
 * @param {date} date
 * @returns string
 */
export function formatGermanDate(date: Date) {
  if (Object.prototype.toString.call(date) !== '[object Date]') {
    throw new Error('Date is not an object!');
  }

  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

/**
 * Format given date to american date format
 *
 * @param {date} date
 * @returns string
 */
export function formatAmericanDate(date: Date) {
  if (Object.prototype.toString.call(date) !== '[object Date]') {
    throw new Error('Date is not an object!');
  }

  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
}

/**
 * Generate time ago string
 *
 * @param {string} value
 * @param {string} format
 * @returns string
 */
export function timeAgo(value: Date, format: string) {
  let dateFormat = format;
  const date = moment(value);
  const now = moment().startOf('second');

  if (!format) {
    dateFormat = 'DD.MM.YYYY';
  }

  const startOfOneHourAgo = now.clone().add(-1, 'h');
  const startOfToday = now.clone().startOf('day');
  const startOfYesterday = now.clone().add(-1, 'd').startOf('day');

  let formattedTimeAgo = '';

  if (date.isBefore(startOfYesterday)) {
    formattedTimeAgo = moment(value).format(dateFormat);
  }

  if (date.isSameOrAfter(startOfYesterday) && date.isBefore(startOfToday)) {
    formattedTimeAgo = i18next.t('helpers.timeAgo.yesterday');
  }

  if (date.isSameOrAfter(startOfToday) && date.isBefore(startOfOneHourAgo)) {
    const diffHours = now.diff(date, 'hours');
    formattedTimeAgo =
      diffHours === 1
        ? i18next.t('helpers.timeAgo.hour', { hour: diffHours.toString() })
        : i18next.t('helpers.timeAgo.hours', { hours: diffHours.toString() });
  }

  if (date.isSameOrAfter(startOfOneHourAgo) && date.isSameOrBefore(now)) {
    const diffMinutes = now.diff(date, 'minutes');
    formattedTimeAgo =
      diffMinutes === 1
        ? i18next.t('helpers.timeAgo.minute', { minute: diffMinutes.toString() })
        : i18next.t('helpers.timeAgo.minutes', { minutes: diffMinutes.toString() });
  }

  return formattedTimeAgo;
}

/**
 * Get date of tomorrow
 *
 * @returns object
 */
export function getDateOfTomorrow() {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow;
}

/**
 * Group tasks by date
 *
 * @param {array} tasks
 * @param {string} mode
 * @returns object
 */
export function createColumnByTaskDates(tasks: ITaskProps[], mode: string) {
  let group = {};
  // group by given mode
  if (mode === 'dueDate') {
    group = {
      id: 'dueDateGroup',
      title: i18next.t('helpers.status.dueAndOverdue'),
      singleColumn: false,
      tasks: tasks.filter((val) => new Date(val.dueDate as string) < getDateOfTomorrow())
    };
  } else if (mode === 'upcoming') {
    group = {
      id: 'upcomingGroup',
      title: i18next.t('helpers.status.upcoming'),
      singleColumn: true,
      tasks: tasks.filter((val) => new Date(val.dueDate as string) >= getDateOfTomorrow())
    };
  } else if (mode === 'early') {
    group = {
      id: 'earlyCompletedGroup',
      title: i18next.t('helpers.status.early-completed'),
      singleColumn: true,
      tasks: tasks.filter(
        (val) =>
          moment.utc(val.editDate).format('YYYY-MM-DD') <
          moment.utc(val.dueDate).format('YYYY-MM-DD')
      )
    };
  } else if (mode === 'punctual') {
    group = {
      id: 'punctualCompletedGroup',
      title: i18next.t('helpers.status.punctual-completed'),
      singleColumn: true,
      tasks: tasks.filter(
        (val) =>
          moment.utc(val.editDate).format('YYYY-MM-DD') ===
          moment.utc(val.dueDate).format('YYYY-MM-DD')
      )
    };
  } else if (mode === 'late') {
    group = {
      id: 'lateCompletedGroup',
      title: i18next.t('helpers.status.late-completed'),
      singleColumn: true,
      tasks: tasks.filter(
        (val) =>
          moment.utc(val.editDate).format('YYYY-MM-DD') >
          moment.utc(val.dueDate).format('YYYY-MM-DD')
      )
    };
  }
  return group;
}

/**
 * Group tasks by status
 *
 * @param {array} tasks
 * @param {array} statuses
 * @returns array
 */
export function createColumnsByTaskStatus(tasks: ITaskProps[], statuses: { id: number }[]) {
  const sortedStatus = [statuses[0], statuses[1], statuses[4], statuses[2], statuses[3]];
  const groups: { id: string; title: string; tasks: ITaskProps[] }[] = [];

  sortedStatus.forEach((status) => {
    const list = tasks.filter((task) => task.statusId === status.id);
    groups.push({
      id: `status-${status.id}`,
      title: i18next.t(`taskStatus.${status.id}`),
      tasks: list
    });
  });

  return groups;
}

/**
 * Set page title for routes containers
 *
 * @param {*} type
 * @returns {string}
 */
export function setPageTitleForRoutesContainer(type: string) {
  let title = '';
  switch (type) {
    case 'involved':
      title = i18next.t('page.title.involvedRoutes');
      break;
    case 'all':
      title = i18next.t('page.title.allRoutes');
      break;
    case 'completed':
      title = i18next.t('page.title.completedRoutes');
      break;
    default:
      title = i18next.t('page.title.myRoutes');
      break;
  }

  return title;
}

/** @deprecated use getPivotStyles2 */
export function getPivotStyles(): IPivotStyles {
  return getPivotStylesImpl();
}
export function getPivotStyles2(theme: DefaultTheme): IPivotStyles {
  return getPivotStylesImpl(theme);
}

function getPivotStylesImpl(theme?: DefaultTheme) {
  const bg = theme ? `rgb(${theme.panelHeader.background})` : getTheme().palette.themePrimary;
  const fg = theme ? `rgb(${theme.panelHeader.foreground})` : 'white';

  const linkStyles = {
    ':hover': { backgroundColor: 'transparent' },
    ':active': { backgroundColor: 'transparent' },
    ':hover::before': { backgroundColor: fg },
    ':before': { marginBottom: 5 }
  };

  return {
    linkContent: {},
    count: {},
    linkInMenu: { '*': { color: 'black !important' } },
    overflowMenuButton: {
      color: fg,
      ':active': { color: fg },
      ':hover': { color: fg }
    },
    root: {
      backgroundColor: bg,
      borderBottom: `3px solid ${bg}`,
      color: fg,
      paddingLeft: '10px'
    },
    text: { marginLeft: '0px', color: fg },
    icon: { paddingLeft: '5px', color: fg },
    link: { selectors: linkStyles },
    linkIsSelected: {
      selectors: { ...linkStyles, ':before': { backgroundColor: fg, marginBottom: 5 } }
    }
  };
}

interface IDocumentFieldsProps extends IFieldLinkProps {
  groupIndex: number;
  fieldIndex: number;
  groupFieldIndex?: number;
  mediaValueIndex?: number;
}

export function getFormDocumentFields(groups: IFormGroupProps[]) {
  const documentFields: IDocumentFieldsProps[] = [];

  const addDocumentField = (
    fieldLink: IFieldLinkProps,
    groupIndex: number,
    fieldIndex: number,
    groupFieldIndex?: number
  ) => {
    const { field, value } = fieldLink;
    const documentFieldValue = value as IFileProps;
    if (field?.fieldType === 10 && documentFieldValue?.blobFile) {
      // add documentFields
      documentFields.push({ ...fieldLink, groupIndex, fieldIndex, groupFieldIndex });
    } else if (field?.fieldType === 15 && Array.isArray(documentFieldValue)) {
      // check multi media field
      documentFieldValue.forEach((mediaValue, mediaValueIndex) => {
        if (mediaValue.blobFile) {
          documentFields.push({
            ...fieldLink,
            groupIndex,
            fieldIndex,
            groupFieldIndex,
            mediaValueIndex
          });
        }
      });
    }
  };

  if (groups && groups.length > 0) {
    groups.forEach((group, groupIndex) => {
      group.fields.forEach((fieldLink, fieldIndex) => {
        if (fieldLink.field?.id) {
          // check normal fields
          addDocumentField(fieldLink, groupIndex, fieldIndex);
        } else if (fieldLink.fieldGroup?.id && fieldLink.fieldGroup?.fields?.length) {
          // check fieldgroups
          fieldLink.fieldGroup.fields.forEach((groupFieldLink, groupFieldIndex) => {
            addDocumentField(
              { ...groupFieldLink, relation: fieldLink.relation },
              groupIndex,
              fieldIndex,
              groupFieldIndex
            );
          });
        }
      });
    });

    return documentFields;
  }

  return documentFields;
}

export function getTeamImageInitials(title: string) {
  const splitTitle = title.split(' ');

  let imageInitials = splitTitle[0][0];

  if (splitTitle[1]) {
    imageInitials += splitTitle[1][0];
  }

  return imageInitials;
}

// returns random different colors (for sets of colors)
export function getRandomColorForSet(numOfSteps: number, step: number) {
  let r = 0;
  let g = 0;
  let b = 0;
  const h = step / numOfSteps;
  // eslint-disable-next-line no-bitwise
  const i = ~~(h * 6);
  const f = h * 6 - i;
  const q = 1 - f;
  switch (i % 6) {
    case 0:
      r = 1;
      g = f;
      b = 0;
      break;
    case 1:
      r = q;
      g = 1;
      b = 0;
      break;
    case 2:
      r = 0;
      g = 1;
      b = q;
      break;
    case 3:
      r = 0;
      g = q;
      b = 1;
      break;
    case 4:
      r = f;
      g = 0;
      b = 1;
      break;
    case 5:
      r = 1;
      g = 0;
      b = q;
      break;
    default:
      break;
  }
  // eslint-disable-next-line no-bitwise
  const c = `#${`00${(~~(r * 255)).toString(16)}`.slice(-2)}${`00${(~~(g * 255)).toString(
    16
    // eslint-disable-next-line no-bitwise
  )}`.slice(-2)}${`00${(~~(b * 255)).toString(16)}`.slice(-2)}`;
  return c;
}

// usage: rainbowStop(0.5);
export function getRandomColorForSetStop(h: number) {
  const f = (n: number, k = (n + h * 12) % 12) =>
    0.5 - 0.5 * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  const rgb2hex = (r: number, g: number, b: number) =>
    `#${[r, g, b]
      .map((x) =>
        Math.round(x * 255)
          .toString(16)
          .padStart(2, '0')
      )
      .join('')}`;
  return rgb2hex(f(0), f(8), f(4));
}

// returns any random color
export function getRandomColor() {
  const chars = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += chars[Math.floor(Math.random() * 16)];
  }
  return color;
}

// colors for bar diagrams
export function colorsForDashboards() {
  return [
    '#A9A9A9', // grey for deleted
    '#639FB8',
    '#FFB5C1',
    '#9CE1FF',
    '#FFF982',
    '#B3AF64',
    '#9173B8',
    '#FFD69E',
    '#C79CFF',
    '#82FFA3',
    '#64B378',
    '#B88072',
    '#BAEB7A',
    '#FFAF9C',
    '#7D9EFF',
    '#8F99B5',
    '#B8B533',
    '#73BDEB',
    '#FFFD94',
    '#FF474A',
    '#C28889',
    '#A2FAC7',
    '#85478A',
    '#B9FF78',
    '#CCAF64',
    '#4143B8',
    '#FFEC3D',
    '#FF6D7C',
    '#CCAFD2',
    '#0697EA',
    '#8A6287',
    '#A2ED7B',
    '#CBB7E5',
    '#ED867B',
    '#67DFE6',
    '#D6BC7A',
    '#FF1F46',
    '#CFFFB8',
    '#29B5FF',
    '#E6C650',
    '#7978EB',
    '#61AD57',
    '#B6AFFA',
    '#A1FA96',
    '#FAAE7D',
    '#AD7E5F',
    '#4F82A8',
    '#F58E8C',
    '#8CC7F5',
    '#F3F573',
    '#C6C78D',
    '#995D92',
    '#E6D79C',
    '#E69CDD',
    '#85E6DD',
    '#97B8B5',
    '#F7F377',
    '#ABA746',
    '#61A7C9',
    '#F75E6D',
    '#C97D85',
    '#D6B3E8',
    '#8C6D9C',
    '#C6AC8F',
    '#81DB92',
    '#BCE8C4',
    '#31459E',
    '#ACDB81',
    '#B3BCE8',
    '#C79D8F',
    '#D1E8BC',
    '#FF392E',
    '#6DB2F2',
    '#B57774',
    '#C1D11B',
    '#148DFF',
    '#FFB58A',
    '#BAFFFF',
    '#FF554D',
    '#B3FF91',
    '#FF65F4',
    '#FFF763',
    '#9B63FF',
    '#C8FFF1',
    '#AC7DFF',
    '#C2B2AC',
    '#3B9963',
    '#ECFF85',
    '#7CCC6E',
    '#FF7165',
    '#6C9FCC',
    '#AC9EFF',
    '#79CDFF',
    '#8FFFBA',
    '#FF88EF',
    '#FFC276',
    '#70FFF4',
    '#FF9F9A',
    '#0391FF',
    '#FFDCAB',
    '#FFFD42'
  ];
}

export function getIndustryTypes() {
  const industryTypes = [];

  // create option out of i18n references and push option-object into industryTypes
  for (let key = 0; key <= 148; key += 1) {
    const id = `helpers.industry.${key}`;
    industryTypes.push({
      key,
      text: i18next.t(id)
    });
  }

  return industryTypes;
}

export function isEmpty(valueToCheck: unknown): boolean {
  return (
    valueToCheck === null ||
    valueToCheck === undefined ||
    (Array.isArray(valueToCheck) && valueToCheck.length === 0) ||
    (typeof valueToCheck === 'string' && valueToCheck.trim().length === 0)
  );
}

function findEmptyRequiredField(fieldLink: IFieldLinkProps): {
  isEmptyRequiredField: boolean;
  fieldLink: IFieldLinkProps;
} {
  const { visibility, value, field } = fieldLink;
  const isRequired = visibility === 2;

  let isEmptyRequiredField = false;

  if (!isRequired) return { isEmptyRequiredField, fieldLink };

  if (field?.appendChanges) {
    const longTextFieldValue = value as ILongTextFieldAppendChangesProps;

    // append changes longtext field
    isEmptyRequiredField = isEmpty(longTextFieldValue?.currentValue);
  } else {
    isEmptyRequiredField = isEmpty(value);
  }

  return { isEmptyRequiredField, fieldLink };
}

export function findEmptyRequiredFields(
  group: IFormGroupProps
): { isEmptyRequiredField: boolean; fieldLink: IFieldLinkProps }[] {
  const fieldLinks: IFieldLinkProps[] = [];

  group.fields.forEach((field) => {
    if (field.fieldGroup?.fields) {
      fieldLinks.push(...field.fieldGroup.fields);
    } else {
      fieldLinks.push(field);
    }
  });

  return fieldLinks.map(findEmptyRequiredField);
}

export function markAllRequiredFields(groups: IFormGroupProps[], scrollIntoView?: boolean) {
  const fieldLinks = groups.flatMap(findEmptyRequiredFields);

  fieldLinks.forEach(({ isEmptyRequiredField, fieldLink }) => {
    if (!fieldLink.field?.id) return;

    const element = document.getElementById(fieldLink.field.id);

    if (!element) return;

    if (isEmptyRequiredField) {
      // add error class
      element.classList.add('c-field-required-error');
    } else {
      // remove error class
      element.classList.remove('c-field-required-error');
    }
  });

  const emptyRequiredFields = fieldLinks.filter((c) => c.isEmptyRequiredField);

  if (scrollIntoView && emptyRequiredFields?.length && emptyRequiredFields[0].fieldLink.field?.id) {
    // scroll into view of first empty required field
    const element = document.getElementById(emptyRequiredFields[0].fieldLink.field.id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  return emptyRequiredFields;
}

// function to check if a number includes bit flag
export function bitTest(number: number, bitFlag: number): boolean {
  return (number & bitFlag) === bitFlag;
}

// function to add bit flag to number
export function bitAdd(number: number, bitFlag: number): number {
  return number | bitFlag;
}

// function to remove bit flag from number
export function bitRemove(number: number, bitFlag: number): number {
  return number & ~bitFlag;
}

// function to toggle bit flag in number
export function bitToggle(number: number, bitFlag: number): number {
  return number ^ bitFlag;
}

export function onOpenWopi(fileId: string): void {
  const tenantId = window.localStorage.getItem('tenantId');

  const wopiUrl = `${window.location.origin}/wopi-file/${fileId}/${tenantId}`;

  // open url in new tab
  if (window.open) {
    window.open(wopiUrl, '_blank');
  } else {
    window.location.assign(wopiUrl);
  }
}

interface IGetDefaultGroupsProps {
  startFormGroups: IFormGroupProps[];
  instanceId?: string;
  fieldLinksToMap?: IFieldLinkProps[];
  outboundFields?: IOutboundFieldProps[];
}

function compareFieldGroupFields(fields1: IFieldLinkProps[], fields2: IFieldLinkProps[]): boolean {
  if (fields1.length !== fields2.length) return false;

  const fieldIds1 = fields1.map((c) => c.field?.id);
  const fieldIds2 = fields2.map((c) => c.field?.id);

  return fieldIds1.every((c) => fieldIds2.includes(c));
}

interface IFieldLinksValuesProps {
  fieldLink: IFieldLinkProps;
  fieldLinks1: IFieldLinkProps[];
  fieldLinks2: IFieldLinkProps[];
  outboundFields: IOutboundFieldProps[];
}

function getFieldLinkValue(fieldLinkValues: IFieldLinksValuesProps): IFieldLinkProps {
  const { fieldLink, fieldLinks1, fieldLinks2, outboundFields } = fieldLinkValues;

  const outboundField = outboundFields.find((c) => {
    return c.foreignFieldId === fieldLink.field?.id;
  });

  if (!outboundField) return fieldLink;

  const fieldLink1 = fieldLinks1?.find((c) => c.field?.id === outboundField.ownField?.id);
  if (fieldLink1) {
    return { ...fieldLink, value: fieldLink1.value };
  }

  const fieldLink2 = fieldLinks2.find((c) => c.field?.id === outboundField.ownField?.id);
  if (fieldLink2) {
    return { ...fieldLink, value: fieldLink2.value };
  }

  return fieldLink;
}

async function getFieldGroupFieldValues(
  fieldLinkValues: IFieldLinksValuesProps
): Promise<IFieldLinkProps> {
  const { fieldLink, fieldLinks1, fieldLinks2, outboundFields } = fieldLinkValues;

  let { fieldGroup } = fieldLink;

  if (!fieldGroup || !fieldGroup.fields) return fieldLink;

  const fieldGroupFields = fieldGroup.fields.map((fieldGroupFieldLink) => {
    return getFieldLinkValue({
      fieldLink: fieldGroupFieldLink,
      fieldLinks1,
      fieldLinks2,
      outboundFields
    });
  });

  // get documents to store
  const documents = getSectionDocuments(fieldGroupFields);

  // remove documents from field group fields
  fieldGroup = prepareFieldGroupForEvaluation(fieldLink).fieldGroup;

  // evaluate fieldgroup and set field values
  const evaluatedFieldGroupLink = await evaluateFieldGroup({
    ...fieldLink,
    fieldGroup: { ...fieldGroup, fields: fieldGroupFields }
  });

  const evaluatedFieldGroupLinkClone = { ...evaluatedFieldGroupLink };

  documents.forEach(({ file, fieldLinkId }) => {
    let field: IFieldLinkProps | undefined;

    const fieldLinkIndex = evaluatedFieldGroupLinkClone?.fieldGroup?.fields?.findIndex(
      (fieldLink) => fieldLink.id === fieldLinkId
    );

    // check if index is valid
    const validIndex = typeof fieldLinkIndex === 'number' && fieldLinkIndex > -1;

    if (validIndex && evaluatedFieldGroupLinkClone?.fieldGroup?.fields?.[fieldLinkIndex]) {
      field = evaluatedFieldGroupLinkClone?.fieldGroup?.fields[fieldLinkIndex];

      if (field) field.value = file;
    }
  });

  // the fields may have changed as a result of the validation
  // so we need to compare the fieldgroup fields with the evaluated fieldgroup fields
  const areFieldsEqual = compareFieldGroupFields(
    fieldGroup?.fields || [],
    evaluatedFieldGroupLinkClone.fieldGroup?.fields || []
  );

  if (!areFieldsEqual) {
    // the fields have changed so we need to get the field values again
    return getFieldGroupFieldValues({
      fieldLink: evaluatedFieldGroupLinkClone,
      fieldLinks1,
      fieldLinks2,
      outboundFields
    });
  }

  return evaluatedFieldGroupLinkClone;
}

interface IGetGroupFieldsProps {
  group: IFormGroupProps;
  fieldLinks1: IFieldLinkProps[];
  fieldLinks2: IFieldLinkProps[];
  outboundFields: IOutboundFieldProps[];
}

function getGroupFields(getGroupFieldsProps: IGetGroupFieldsProps) {
  const { group, fieldLinks1, fieldLinks2, outboundFields } = getGroupFieldsProps;

  return Promise.all(
    group.fields.map((fieldLink) => {
      const fieldValuesProps = { fieldLink, fieldLinks1, fieldLinks2, outboundFields };

      if (fieldLink.fieldGroup) {
        return getFieldGroupFieldValues(fieldValuesProps);
      }

      if (fieldLink.field) {
        return getFieldLinkValue(fieldValuesProps);
      }

      return fieldLink;
    })
  );
}

export async function getDefaultGroups({
  startFormGroups, // groups to set the default values to
  instanceId, // instance id to get the instance field values
  fieldLinksToMap = [], // field links to map to the groups
  outboundFields = [] // carries the information about which fields are mapped to each other
}: IGetDefaultGroupsProps): Promise<IFormGroupProps[]> {
  // set the fields values in the groups to the default values from the outboundFields
  // priority  defaultFieldLinks > instanceFieldValues > groupFieldValues

  // if there are no outbound fields then return the groups
  if (!outboundFields || outboundFields.length === 0) return Promise.resolve(startFormGroups);

  let groupsClone = cloneDeep(startFormGroups);
  let instanceFieldValues: IFieldLinkProps[] = [];

  if (instanceId) {
    instanceFieldValues = await getInstanceFieldValues(instanceId);
  }

  const fieldLinks1 = fieldLinksToMap;
  const fieldLinks2 = instanceFieldValues;

  // iterate through groups and fields and set the values
  groupsClone = await Promise.all(
    groupsClone.map(async (group) => {
      const fields = await getGroupFields({ group, fieldLinks1, fieldLinks2, outboundFields });

      return { ...group, fields };
    })
  );

  return groupsClone;
}

export function getPagesLocations() {
  return [
    {
      key: PageLocation.pages,
      text: i18next.t('analyticsAdministration.location.pages')
    },
    {
      key: PageLocation.projectLists,
      text: i18next.t('analyticsAdministration.location.projectLists')
    },
    {
      key: PageLocation.project,
      text: i18next.t('analyticsAdministration.location.project')
    },
    {
      key: PageLocation.projectDetails,
      text: i18next.t('analyticsAdministration.location.projectDetails')
    },
    {
      key: PageLocation.projectTask,
      text: i18next.t('analyticsAdministration.location.projectTask')
    },
    {
      key: PageLocation.processLists,
      text: i18next.t('analyticsAdministration.location.processLists')
    },
    {
      key: PageLocation.processList,
      text: i18next.t('analyticsAdministration.location.processList')
    },
    {
      key: PageLocation.processDetails,
      text: i18next.t('analyticsAdministration.location.processDetails')
    },
    {
      key: PageLocation.processTask,
      text: i18next.t('analyticsAdministration.location.processTask')
    },
    {
      key: PageLocation.tasksLists,
      text: i18next.t('analyticsAdministration.location.tasksLists')
    }
  ];
}
