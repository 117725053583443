import { loginRequest } from 'features/Authentication';
import WelcomePage from 'pages/WelcomePage';
import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { isInIframe } from 'utils/helpers';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react';
import { Spinner } from '@fluentui/react';
import { app, authentication } from '@microsoft/teams-js';

// see vite.config.ts
// eslint-disable-next-line import/no-unresolved
import AppContainer from '../../App?filterEvocomProduct=evocom';
// eslint-disable-next-line import/no-unresolved
import AiAppContainer from '../AI/App?filterEvocomProduct=ai';

function Auth() {
  const [loginHint, setLoginHint] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [msTeamsInitialized, setMsTeamsInitialized] = useState(false);

  const msal = useMsal();
  const { instance, accounts, inProgress } = msal;
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    app
      .initialize()
      .then(() => {
        setMsTeamsInitialized(true);
        app.getContext((context) => {
          window.sessionStorage.setItem('msTeamsEnvironment', true);
          setLoginHint(context.loginHint);
        });
      })
      .catch(() => {
        window.sessionStorage.removeItem('msTeamsEnvironment');
      });
  }, []);

  const knownEpUser = window.localStorage.getItem('knownEpUser') || doesCookieExist('knownEpUser');
  const msAuthStart = window.location.href.includes('authentication-start');
  const msAuthEnd = window.location.href.includes('authentication-end');

  const getMsalLoginHint = useCallback(
    (eMail) => {
      // loginhint set as eMail. Is in localStorage because of the popup window
      const msTeamsLoginHint = window.localStorage.getItem('msTeamsLoginHint');

      return msTeamsLoginHint || eMail || loginHint;
    },
    [loginHint]
  );

  const onMsalLogin = useCallback(
    (eMail, redirectUri) => {
      setTimeout(() => {
        // set eMail for login error logging
        window.localStorage.setItem('loginEmail', eMail);

        const loginHint = getMsalLoginHint(eMail);

        const fullLoginRequest = { ...loginRequest };

        if (loginHint) {
          fullLoginRequest.loginHint = loginHint;
        }

        if (redirectUri) {
          fullLoginRequest.redirectUri = redirectUri;
        }

        if (isInIframe() && !msAuthStart) {
          instance.loginPopup(fullLoginRequest);
        } else if (!isInIframe()) {
          localStorage.setItem('redirectUri', window.location.href);
          instance.loginRedirect(fullLoginRequest);
        } else {
          window.location = '/';
        }
      }, 100);
    },
    [getMsalLoginHint, instance, msAuthStart]
  );

  useEffect(() => {
    if (msAuthEnd && isAuthenticated && msTeamsInitialized) {
      // notifiy succsess to close auth ms-teams popup window
      authentication.notifySuccess();
    }
  }, [msAuthEnd, isAuthenticated, msTeamsInitialized]);

  useEffect(() => {
    if (msAuthStart && !initialized) {
      setInitialized(true);
      onMsalLogin(null, `${window.location.origin}/authentication-end`);
    }
  }, [msAuthStart, initialized, onMsalLogin]);

  if (msAuthEnd || msAuthStart) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <Spinner size={3} />
      </div>
    );
  }

  const queryParam = window.location.search;

  if (queryParam) {
    window.localStorage.setItem('initQueryParam', queryParam);
  }

  function getForceLoginState() {
    let onBoardingActive = false;
    if (window.baseConfig) {
      ({ onBoardingActive } = window.baseConfig);
    }

    if (onBoardingActive) {
      return !isInIframe() && knownEpUser;
    }

    return !isInIframe();
  }

  function doesCookieExist(cookieNameToCheck) {
    return document.cookie
      .split(';')
      .some((cookie) => cookie.trim().startsWith(`${cookieNameToCheck}=`));
  }

  function onLogout() {
    if (isInIframe()) {
      instance.logoutPopup();
    } else {
      instance.logoutRedirect();
    }
  }

  if (inProgress === 'none') {
    let authError;

    if (!authError && doesCookieExist('epMsalAuthStarted')) {
      authError = 'User cancelled the flow.';
    }

    if (!isAuthenticated && getForceLoginState()) {
      // not authenticated
      onMsalLogin();

      return null;
    }

    if (isAuthenticated) {
      // authenticated
      instance.setActiveAccount(accounts[0]);

      // remove cookie
      if (doesCookieExist('epMsalAuthStarted')) {
        document.cookie = 'epMsalAuthStarted=;max-age=0';
      }

      // next time the user should be forwarded directly
      if (accounts[0]?.username) {
        document.cookie = 'knownEpUser=true;max-age=31536000';
        window.localStorage.setItem('knownEpUser', accounts[0].username);
      }
    }

    if (import.meta.env.VITE_EVOCOM_PRODUCT === 'ai') {
      return (
        <>
          <AuthenticatedTemplate>
            <AiAppContainer logout={onLogout} />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <WelcomePage
              defaultAuthError={authError}
              defaultLoginHint={loginHint}
              login={(eMail) => onMsalLogin(eMail)}
              appType="ai"
            />
          </UnauthenticatedTemplate>
        </>
      );
    }

    if (import.meta.env.VITE_EVOCOM_PRODUCT === 'evocom') {
      return (
        <BrowserRouter>
          <AuthenticatedTemplate>
            <AppContainer logout={onLogout} />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <WelcomePage
              defaultAuthError={authError}
              defaultLoginHint={loginHint}
              login={(eMail) => onMsalLogin(eMail)}
              appType="evocom"
            />
          </UnauthenticatedTemplate>
        </BrowserRouter>
      );
    }
  }

  return null;
}

export default Auth;
