import { fetchAIJson } from '../../../services/api2';
import {
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  createSmallApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { IApiObjectArray, Ref, Uuid, VoidParams } from './base-types';
import { createFieldRefs } from './utils';
import { AGENT_VERSION, AgentIncludes, IAgentVersion, IAgentVersionId } from './agents';
import { IKnowledgeBase, KNOWLEDGE_BASE } from './knowledgeBases';

export const ACCEPTED_FILE_TYPES = [
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.txt',
  '.jpg',
  '.jpeg',
  '.png'
];

export enum MessageContentFragmentType {
  Text = 'text',
  ToolCall = 'tool-call',
  SubMessage = 'sub-message'
}

interface IMessageContentFragmentBase {
  $type: MessageContentFragmentType;
  id: Uuid;
}

export interface IMessageContentFragmentText extends IMessageContentFragmentBase {
  $type: MessageContentFragmentType.Text;
  text: string;
}

export interface IMessageContentFragmentToolCall extends IMessageContentFragmentBase {
  $type: MessageContentFragmentType.ToolCall;
  arguments: string;
  functionName: string;
  pluginName: string;
  result: string;
}

export interface IMessageContentFragmentSubMessage extends IMessageContentFragmentBase {
  $type: MessageContentFragmentType.SubMessage;
  messageId: Uuid;
}

export type IMessageContentFragment =
  | IMessageContentFragmentText
  | IMessageContentFragmentToolCall
  | IMessageContentFragmentSubMessage;

export interface IMessageBase extends IApiObjectData {
  id: Uuid;
  author: Author;
  createdAt: string;
  updatedAt: string;
  text: string | null;
  content: IMessageContentFragment[];
  files: IChatFile[] | null;
}

export interface IMessageUser extends IMessageBase {
  author: Author.User;
}

export interface IMessageAI extends IMessageBase {
  author: Author.AI;
  agent: IAgentVersion | null;
  isFailed: boolean;
}

export type IMessage = IMessageUser | IMessageAI;

export interface IChatFile {
  chatId: Uuid;
  contentType: string;
  createdAt: string;
  id: Uuid;
  length: number;
  name: string;
  updatedAt: string;
}

export enum Author {
  User = 'user',
  AI = 'ai'
}

export enum ChatState {
  Running = 'running',
  OnHold = 'onHold',
  Completed = 'completed'
}

export interface IChat extends IApiObjectData {
  id: Uuid;
  createdAt: string;
  updatedAt: string;
  userId: Uuid;
  files: IChatFile[] | null;
  name?: string;
  messages: IMessage[] | null;
  agent: Ref<IAgentVersion> | null;
  knowledgeBase: Ref<IKnowledgeBase> | null;
  isPinned: boolean;
  state: ChatState;
  temperature: number | null;
}

export enum ChatIncludes {
  Agent = 'agent',
  AgentPlugins = 'agent.plugins',
  KnowledgeBase = 'knowledgeBase'
}

function chatIncludesToAgentIncludes(includes: ChatIncludes[]): AgentIncludes[] {
  return includes
    .map((include) => {
      switch (include) {
        case ChatIncludes.AgentPlugins:
          return AgentIncludes.Plugins;
        default:
          return null;
      }
    })
    .filter((include) => include);
}

function compileChatIncludes(includes: ChatIncludes[]) {
  const search = new URLSearchParams();

  for (const include of includes) {
    if (include === ChatIncludes.AgentPlugins && !includes.includes(ChatIncludes.Agent)) {
      search.append('includes', ChatIncludes.Agent);
    }

    search.append('includes', include);
  }
  return search.size ? `?${search}` : '';
}

export const CHAT: IApiObjectType<Uuid, IChat, IChat> = {
  id: 'Chat',
  createRefs(cache, data, key) {
    return createFieldRefs(cache, data, [
      {
        path: 'agent',
        type: AGENT_VERSION,
        includes: chatIncludesToAgentIncludes(key.includes as ChatIncludes[]),
        params: (data) =>
          data.agent
            ? ({ id: data.agent.id, version: data.agent.version } as IAgentVersionId)
            : null
      },
      {
        path: 'knowledgeBase',
        type: KNOWLEDGE_BASE,
        includes: [],
        params: (data) => (data.knowledgeBase ? data.knowledgeBase.id : null)
      }
    ]);
  },
  async load(cache: IApiObjectCache, id: Uuid, abort: AbortSignal, { includes }): Promise<IChat> {
    if (!includes) throw new Error('CHAT requires includes');

    const result = await fetchAIJson(
      {
        url: `chats/${encodeURIComponent(id)}${compileChatIncludes(includes as ChatIncludes[])}`,
        abort
      },
      { addUserId: true }
    );

    return createSmallApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(CHAT, id, ApiObjectVariant.Small, includes),
      result
    ) as IChat;
  }
};

export const CHATS: IApiObjectType<VoidParams, IApiObjectArray<IChat>> = {
  id: 'Chats',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IChat>, key) {
    createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: CHAT,
        includes: key.includes,
        params: () => item.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal,
    { includes }
  ): Promise<IApiObjectArray<IChat>> {
    if (!includes) throw new Error('CHATS requires includes');

    const result = await fetchAIJson(
      { url: `Chats${compileChatIncludes(includes as ChatIncludes[])}`, abort },
      { addUserId: true }
    );

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(CHATS, params, ApiObjectVariant.Small, includes),
      { items: result }
    ) as IApiObjectArray<IChat>;
  }
};

export enum ChatMessageIncludes {
  Agent = 'agent',
  Files = 'files'
}

function compileChatMessageIncludes(includes: ChatMessageIncludes[]) {
  const search = new URLSearchParams();

  for (const include of includes) {
    search.append('includes', include);
  }
  return search.size ? `?${search}` : '';
}

export const CHAT_MESSAGE: IApiObjectType<{ chat: Uuid; message: Uuid }, IMessage, IMessage> = {
  id: 'ChatAssistantMessage',
  createRefs(cache, data): void {
    createFieldRefs(cache, data, [
      {
        path: 'agent',
        type: AGENT_VERSION,
        params: () => ('agent' in data ? data.agent?.id : null),
        includes: []
      }
    ]);
  },
  async load(cache, params, abort, { includes }): Promise<IMessage> {
    const result = await fetchAIJson(
      {
        url: `chats/${params.chat}/messages/${params.message}${compileChatMessageIncludes(
          includes as ChatMessageIncludes[]
        )}`,
        abort
      },
      { addUserId: true }
    );

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(CHAT_MESSAGE, params, ApiObjectVariant.Small, includes),
      result
    ) as IMessage;
  }
};
