import { MainCommandBar } from 'components';
import { AppContext } from 'features/App';
import { useHeaderStylesOnScroll } from 'hooks';
import { ContainerWrapper } from 'layouts';
import NoAccessContainer from 'pages/NoAccess';
import { createRef, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatAmericanDate } from 'utils/helpers';
import { DatePicker } from '@fluentui/react';
import ActivitiesListInfiniteScroll from 'components/lists/ActivitiesList/ActivitiesListInfiniteScroll';

const AllActivitiesDatePickerStyled = styled(DatePicker)`
  .ms-TextField-field {
    max-width: 120px;
    cursor: pointer;
  }
  .ms-TextField-fieldGroup {
    background: none;
  }
`;

function ActivitiesContainer() {
  const { t } = useTranslation();

  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentUser, msTeamId } = globalAppState;

  // List props
  const [searchProps, setSearchProps] = useState({
    teamId: msTeamId || null,
    startDate: null,
    endDate: null,
    notificationsUserId: currentUser?.userId,
    loadingPage: 0,
    listEnd: false
  });

  // Loading states
  const listRef = createRef();

  useHeaderStylesOnScroll(listRef);

  const setFilterDate = useCallback(
    (dateType, date) => {
      const newSearchProps = {
        ...searchProps,
        [dateType]: formatAmericanDate(date),
        loadingPage: 0
      };

      setSearchProps(newSearchProps);
    },
    [searchProps]
  );

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  return (
    <>
      <MainCommandBar
        farItems={[
          {
            key: 'calendarStart',
            className: 'command-bar-item',
            name: (
              <AllActivitiesDatePickerStyled
                maxDate={!searchProps.endDate ? new Date() : new Date(searchProps.endDate)}
                placeholder={searchProps.startDate || t('allActivities.datePicker.startDate')}
                borderless
                onSelectDate={(date) => setFilterDate('startDate', date)}
              />
            )
          },
          {
            key: 'calendarEnd',
            className: 'command-bar-item',
            name: (
              <AllActivitiesDatePickerStyled
                maxDate={new Date()}
                minDate={!searchProps.startDate ? false : new Date(searchProps.startDate)}
                placeholder={searchProps.endDate || t('allActivities.datePicker.endDate')}
                borderless
                onSelectDate={(date) => setFilterDate('endDate', date)}
              />
            )
          }
        ]}
      />
      <ContainerWrapper ref={listRef}>
        <ActivitiesListInfiniteScroll params={searchProps} />
      </ContainerWrapper>
    </>
  );
}

export default ActivitiesContainer;
