import {
  ApiObjectVariant,
  AsSmallApiObjectData,
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { ApprovalTaskStatus, DateType, RequestStatus, TaskStatus, TaskType } from '../../../types';
import { ITeam, IUser, TEAM, USER } from './users';
import { createFieldRefs, simpleIdObject } from './utils';
import {
  DateTime,
  DEFAULT_ITEMS_PER_PAGE,
  IApiObjectArray,
  IIdAndName,
  ITextItem,
  Ref,
  Uuid
} from './base-types';
import { IApiListType } from '../list';
import { ITagItem, TAG } from './tags';
import { UUID_NULL } from '../../../utils/helpers';
import { fetchJson } from '../../../services/api2';
import { ICustomColumn } from './customColumns';

interface ITaskPermissions {
  update: boolean;
  updateRequest: boolean;
  delegate: boolean;
  delete: boolean;
  updateDates: boolean;
  updateLinks: boolean;
  reOpen: boolean;
  viewInstance: boolean;
}

export interface ITask extends IApiObjectData {
  id: Uuid;
  intId: number;
  title: string | null;
  allTitles: ITextItem[] | null;

  dueDate: DateTime | null;
  startDate: DateTime | null;
  duration: number;

  creationDate: DateTime | null;
  creator: Ref<IUser>;

  editDate: DateTime;
  editor: Ref<IUser>;

  statusId: TaskStatus;
  requestStatusId: RequestStatus;
  reqRejectReason: string | null;
  approvalStatusId: ApprovalTaskStatus;
  approvalStatusDate: DateTime | null;

  typeId: TaskType;
  priority: number;
  effort: number;
  rolename: string | null;

  parentTaskId: Uuid;
  parentTitle: string | null;
  subTaskIds: Uuid[] | null;

  assignedTo: Ref<IUser>;
  assignedToTeam: Ref<ITeam>;

  routeInstanceId: Uuid;
  routeInstanceIntId: number;
  routeInstanceName: string | null;
  routeDefinitionId: Uuid;
  routeDefinitionName: string | null;

  numberSubtasks: number;
  numberCompletedSubtasks: number;

  projectId: Uuid | null;
  projectName: string | null;
  sprintId: Uuid | null;
  sprintName: string | null;

  sortOrder: number;
  successors: Uuid[] | null;
  predecessors: Uuid[] | null;

  newChecklistItems: Uuid[] | null;
  newComments: Uuid[] | null;
  newFiles: Uuid[] | null;

  permissions: ITaskPermissions;

  // FullTaskItem
  description: string | null;
  allDescriptions: ITextItem[] | null;
  topmostTaskId: Uuid | null;
  routeStepId: Uuid | null;
  tags: Ref<ITagItem>[] | null;
  textSubmit: string | null;
  textApprove: string | null;
  textReject: string | null;
  numberComments: number;
  numberSubItems: number;
  numberFiles: number;
  includedPages: Uuid[] | null;
  customColumns: ICustomColumn[] | null;
  templateId: Uuid | null;
  url: string | null;
}

// TODO: there's probably more than this...
type SmallTaskFields = 'id' | 'title';
export type ISmallTask = AsSmallApiObjectData<ITask> & Pick<ITask, SmallTaskFields>;

export const TASK: IApiObjectType<string, ITask, ISmallTask> = simpleIdObject<ITask>({
  id: 'Task',
  url: (id) => `Task/${id}`,
  fields: [
    { path: 'creator', type: USER, params: (data) => data.creator?.userId },
    { path: 'editor', type: USER, params: (data) => data.editor?.userId },
    { path: 'assignedTo', type: USER, params: (data) => data.assignedTo?.userId }
  ],
  dynFields: (task) =>
    task.tags?.map((tag, index) => ({ path: `tags/${index}`, type: TAG, params: () => tag.id })) ??
    []
});

export enum TaskSearchType {
  NotSet = 0,
  AllOpenTasks = 1,
  MyOpenTasks = 2,
  OpenTasksByMe = 3,
  OpenMentions = 4,
  CompletedTasks = 5,
  AllTasks = 6
}

export enum TaskSortType {
  Default = 0,
  DueDate = 1,
  EditDate = 2,
  SortOrder = 3
}

export enum TaskNumberGrouping {
  DueDate = 1,
  Status = 2,
  AssignedTo = 3,
  FinishDate = 4,
  Priority = 5,
  Project = 6,
  Sprint = 7,
  SprintAndStatus = 8
}

export interface ISearchTasksApiRequest {
  /** The ID of a task, to get all its subtasks. */
  parentTaskId?: string;
  /** An array of user IDs, to get tasks created by those users. */
  creatorIds?: string[];
  /** An array of team IDs, to get tasks assigned to those teams. */
  assignedToTeamIds?: string[];
  /** An array of user IDs, to get tasks assigned to those users. */
  assignedToIds?: string[];
  /** An array of status IDs, to get tasks with those statuses. */
  statuses?: TaskStatus[];
  /** An array of type IDs, to get tasks of those types. */
  types?: TaskType[];
  /** An array of priority values, to get tasks with those priorities. */
  priorities?: number[];
  /** An array of tag IDs to filter the tasks by. */
  tagIds?: string[];
  /** An array of route definition IDs to get tasks belonging to routes of those templates. */
  definitionIds?: string[];
  /** An array of project IDs, to get tasks belonging to those projects. */
  projectIds?: string[];
  routeStepId?: string;
  /** Filters tasks to a specific sprint. */
  sprintId?: string;
  onlyTopLevel?: boolean;
  /** Filters to task with the specified date type. */
  dateType?: DateType;
  sortType?: TaskSortType;
  sortAscending?: boolean;
  type?: TaskSearchType;
  /** The request status ID, to get requests with the same status. */
  reqStatusId?: number;
  /** If specified, only tasks due after this date will be returned. Ignored if dateType is set. */
  startDate?: string;
  /** If specified, only tasks due before this date will be returned. Ignored if dateType is set. */
  endDate?: string;
  /** A user ID, to get tasks where this user was mentioned. */
  mentionedId?: string;
  /** If true, all backlogs associated with the tasks are returned regardless of sprint. */
  showAssociatedBacklogs?: boolean;
  /** Custom columns: group ID */
  columnGroupId?: string;
  /** Custom columns: column ID */
  columnId?: string;
  /**
   * True to also load tasks from Planner.
   * Could also be achieved by specifying PlannerTask in types.
   */
  usePlanner?: boolean;
  groupBy?: TaskNumberGrouping;
  /** Search terms, to get matching tasks. */
  searchTerms?: string;
}

export const TASKS_SMALL: IApiListType<
  ISearchTasksApiRequest & { itemsPerPage?: number },
  typeof TASK,
  void,
  ApiObjectVariant.Small
> = {
  id: 'Tasks/Small',
  async fetchPage(
    cache: IApiObjectCache,
    params: ISearchTasksApiRequest & { itemsPerPage?: number },
    pageIndex: number,
    _: unknown,
    abort: AbortSignal
  ) {
    const { items, searchProps } = await fetchJson({
      method: 'POST',
      url: 'Tasks/Small',
      body: {
        pageIndex,
        itemsPerPage: params.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
        ...params
      },
      abort
    });

    return {
      items: items.map((item: object) => {
        const itemAsTask = item as ITask;

        if (itemAsTask.id === UUID_NULL) {
          // Null UUID: this is probably a planner task
          if (itemAsTask.typeId !== TaskType.PlannerTask) {
            throw new Error('unexpected task with null ID');
          }
          // HACK: planner tasks have a unique URL. use that as our ID
          itemAsTask.id = `planner$${(item as ITask).url}`;
        }

        const id = makeApiObjectVariantKey(TASK, itemAsTask.id, ApiObjectVariant.Small);
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, id, item));
        return id;
      }),
      searchProps
    };
  }
};

export interface ITasksFilterProps extends IApiObjectData {
  teams: Ref<ITeam>[] | null;
  assignedTos: Ref<IUser>[] | null;
  creators: Ref<IUser>[] | null;
  statuses: TaskStatus[] | null;
  types: TaskType[] | null;
  routeDefinitions: IIdAndName[] | null;
  priorities: number[] | null;
  projects: IIdAndName[] | null;
}

export const TASKS_FILTER_PROPS: IApiObjectType<ISearchTasksApiRequest, ITasksFilterProps> = {
  id: 'Tasks/FilterProps',
  createRefs(cache: IApiObjectCache, data: ITasksFilterProps) {
    createFieldRefs(
      cache,
      data,
      data.teams.map((team, index) => ({
        path: `teams/${index}`,
        type: TEAM,
        params: () => team.id,
        variant: ApiObjectVariant.Small
      }))
    );
    createFieldRefs(
      cache,
      data,
      data.assignedTos.map((user, index) => ({
        path: `assignedTos/${index}`,
        type: USER,
        params: () => user.userId
      }))
    );
    createFieldRefs(
      cache,
      data,
      data.creators.map((user, index) => ({
        path: `creators/${index}`,
        type: USER,
        params: () => user.userId
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: ISearchTasksApiRequest,
    abort: AbortSignal
  ): Promise<ITasksFilterProps> {
    const result = await fetchJson({
      method: 'POST',
      url: 'Tasks/FilterProps',
      body: params,
      abort
    });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(TASKS_FILTER_PROPS, params),
      result
    ) as ITasksFilterProps;
  }
};

export const TASKS_ASSIGNED_TO: IApiObjectType<ISearchTasksApiRequest, IApiObjectArray<IUser>> = {
  id: 'Tasks/AssignedTo',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IUser>) {
    createFieldRefs(
      cache,
      data,
      data.items.map((user, index) => ({
        path: `items/${index}`,
        type: USER,
        params: () => user.userId
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: ISearchTasksApiRequest,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IUser>> {
    const result = await fetchJson({
      method: 'POST',
      url: 'Tasks/AssignedTo',
      body: params,
      abort
    });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(TASKS_ASSIGNED_TO, params),
      { items: result }
    ) as IApiObjectArray<IUser>;
  }
};

export interface IDashboardNumberItem {
  number: number;
  title: string | null;
  key: Uuid | null;
  colorIndex: number;
}

export const TASKS_NUMBERS: IApiObjectType<
  ISearchTasksApiRequest,
  IApiObjectArray<IDashboardNumberItem>
> = {
  id: 'Tasks/Numbers',
  createRefs() {
    // nothing to do
  },
  async load(
    cache: IApiObjectCache,
    params: ISearchTasksApiRequest,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IDashboardNumberItem>> {
    const result = await fetchJson({
      method: 'POST',
      url: 'Tasks/Numbers',
      body: params,
      abort
    });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(TASKS_NUMBERS, params),
      { items: result }
    ) as IApiObjectArray<IDashboardNumberItem>;
  }
};

export interface ITaskPredecessorsParams {
  taskIds: Uuid[];
}

export interface ITaskPredecessorItem {
  taskId: Uuid;
  id: Uuid;
  title: string | null;
  startDate: DateTime | null;
  dueDate: DateTime | null;
  sortOrder: number;
  statusId: TaskStatus;
}

export const TASK_PREDECESSORS: IApiObjectType<
  ITaskPredecessorsParams,
  IApiObjectArray<ITaskPredecessorItem>
> = {
  id: 'Task/Predecessors',
  createRefs() {
    // nothing to do
  },
  async load(
    cache: IApiObjectCache,
    params: ITaskPredecessorsParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<ITaskPredecessorItem>> {
    const result = await fetchJson({
      url: `Task/Predecessors`,
      method: 'POST',
      body: params,
      abort
    });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(TASK_PREDECESSORS, params),
      { items: result }
    ) as IApiObjectArray<ITaskPredecessorItem>;
  }
};
