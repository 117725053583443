import { Dropdown, IDropdownOption, Shimmer } from '@fluentui/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useThemePreference from '../../hooks/useThemePreference';
import { DeviceThemePreference, useSystemThemePreference } from '../../utils/theme';
import { useNotificationContext } from '../../features/App';
import { KEEP_ALL_EXISTING_COLORS_FOR_NOW } from '../../utils/themeDomains';

const ThemePreferenceSettingStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0.5rem 0.75rem;
`;

export default function ThemePreferenceSetting() {
  const { t } = useTranslation();
  const systemScheme = useSystemThemePreference();

  const options: IDropdownOption[] = useMemo(
    () => [
      {
        key: DeviceThemePreference.System,
        text: t('header.themePreference.system', { context: systemScheme })
      },
      {
        key: DeviceThemePreference.Light,
        text: t('header.themePreference.light')
      },
      {
        key: DeviceThemePreference.Dark,
        text: t('header.themePreference.dark')
      }
    ],
    [systemScheme, t]
  );

  const { showError } = useNotificationContext();
  const { isLoading, colorScheme, setColorScheme } = useThemePreference();
  const [isSetting, setIsSetting] = useState(false);

  if (KEEP_ALL_EXISTING_COLORS_FOR_NOW) return null;

  return (
    <ThemePreferenceSettingStyled>
      {t('header.themePreference.label')}
      <Dropdown
        className="c-dropdown"
        selectedKey={colorScheme}
        options={options}
        disabled={isSetting}
        onRenderTitle={(options, defaultRender) => {
          if (isLoading || isSetting) {
            return <Shimmer styles={{ root: { marginTop: '6px' } }} />;
          }
          return defaultRender(options);
        }}
        onChange={(_, option) => {
          if (!option) return;
          const selected = option.key as DeviceThemePreference;
          if (selected === colorScheme) return;
          if (isSetting) return;

          setIsSetting(true);
          setColorScheme(selected)
            .catch(showError)
            .finally(() => {
              setIsSetting(false);
            });
        }}
      />
    </ThemePreferenceSettingStyled>
  );
}
