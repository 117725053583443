import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { columnPropType } from 'types';
import {
  Checkbox,
  ContextualMenu,
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  Icon,
  SearchBox
} from '@fluentui/react';
import { useTheme } from 'styled-components';

function ListContextMenu(props) {
  const theme = useTheme();
  const { filterOptions, column, target, onSorting, onFilter, onDismiss } = props;

  const [filterdFilterOptions, setFilteredFilterOptions] = useState([]);
  const { t } = useTranslation();

  function onFilterOptionChange(option) {
    const filterOptionsState = { ...column.filterOptionsState, [option.key]: option.checked };
    let isFiltered = false;

    Object.keys(filterOptionsState).map((key) => {
      if (filterOptionsState[key] === true) {
        isFiltered = true;
      }

      return null;
    });

    onFilter({
      ...column,
      filterOptionsState,
      isFiltered
    });
  }

  function onClearFilterOptions(column) {
    onFilter({
      ...column,
      filterOptionsState: null,
      isFiltered: false
    });
  }

  function getContextualMenuOptions(option) {
    const newOption = {
      ...option,
      checked: column.filterOptionsState ? column.filterOptionsState[option.key] : false
    };

    if (option.key === 'noResults') {
      return option;
    }

    const onChange = debounce(() => {
      onFilterOptionChange({ ...newOption, checked: !newOption.checked });
    }, 100);

    return {
      key: newOption.key,
      text: newOption.text,
      onRender: () => (
        <DefaultButton
          styles={{
            root: { border: 'none', height: '36px', width: '100%', background: 'none' },
            flexContainer: { justifyContent: 'left' }
          }}
          onClick={onChange}
        >
          <Checkbox
            styles={{
              text: {
                marginLeft: '15px',
                maxWidth: '240px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }
            }}
            checked={newOption.checked}
            ariaSetSize={15}
            label={newOption.text}
          />
        </DefaultButton>
      )
    };
  }

  function onFilterOptionsSearchChange(ev, newValue) {
    const filteredItems = filterOptions.filter(
      (item) => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
    );

    if (!filteredItems || !filteredItems.length) {
      filteredItems.push({
        key: 'noResults',
        onRender: () => (
          <div
            key="noResults"
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100px',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Icon
              iconName="SearchIssue"
              title={t('contextualMenu.filterSearch.noItemsFound')}
              style={{ marginRight: '5px' }}
            />
            <span>{t('contextualMenu.filterSearch.noItemsFound')}</span>
          </div>
        )
      });
    }

    setFilteredFilterOptions(filteredItems);
  }

  let items = [
    {
      canCheck: true,
      checked: column?.isSorted && !column?.isSortedDescending,
      iconProps: { iconName: 'Ascending' },
      key: 'aToZ',
      name: t('contextualMenu.sort.ascending'),
      onClick: () => onSorting(column, false)
    },
    {
      canCheck: true,
      checked: column?.isSorted && column?.isSortedDescending,
      iconProps: { iconName: 'Descending' },
      key: 'zToA',
      name: t('contextualMenu.sort.descending'),
      onClick: () => onSorting(column, true)
    },
    {
      key: 'divider_1',
      itemType: ContextualMenuItemType.Divider
    }
  ];

  const filterSearchBox = {
    key: 'searchBox',
    onRender: () => (
      <div style={{ borderBottom: `1px solid rgb(${theme.detailsList.contextMenuDivider})` }}>
        <SearchBox
          onChange={(ev, val) => onFilterOptionsSearchChange(ev, val)}
          placeholder={t('contextualMenu.searchBox.placeholder')}
          styles={{
            root: [
              {
                background: `rgb(${theme.detailsList.contextMenuSearchBackground})`,
                margin: '3px',
                marginLeft: '20px',
                border: 'none'
              }
            ]
          }}
        />
      </div>
    )
  };

  const clearFilterButton = {
    key: 'clearFilter',
    onRender: () => (
      <div style={{ borderBottom: `1px solid rgb(${theme.detailsList.contextMenuDivider})` }}>
        <DefaultButton
          text={t('contextualMenu.filter.clear')}
          disabled={!column?.isFiltered}
          iconProps={{ iconName: 'clearFilter' }}
          styles={{
            root: {
              border: 'none',
              height: '36px',
              width: '100%',
              paddingLeft: '28px',
              background: 'none'
            },
            label: { float: 'left', fontWeight: '400' },
            icon: {
              color: `rgb(${theme.detailsList.contextMenuIconColor})`
            }
          }}
          onClick={onClearFilterOptions}
        />
      </div>
    )
  };

  if (filterdFilterOptions.length > 0) {
    items = [
      ...items,
      filterSearchBox,
      clearFilterButton,
      ...filterdFilterOptions.map((option) => getContextualMenuOptions(option))
    ];
  } else if (filterOptions) {
    items = [
      ...items,
      filterSearchBox,
      clearFilterButton,
      ...filterOptions.map((option) => {
        return getContextualMenuOptions(option);
      })
    ];
  }

  return (
    <ContextualMenu
      directionalHint={DirectionalHint.bottomLeftEdge}
      gapSpace={0}
      isBeakVisible
      items={items}
      onDismiss={onDismiss}
      styles={{ root: { minWidth: '200px', maxWidth: '300px', maxHeight: '700px' } }}
      target={target}
    />
  );
}

ListContextMenu.propTypes = {
  filterOptions: PropTypes.arrayOf(PropTypes.any),
  column: columnPropType.isRequired,
  target: PropTypes.instanceOf(Element).isRequired,
  onSorting: PropTypes.func,
  onFilter: PropTypes.func,
  onDismiss: PropTypes.func
};

ListContextMenu.defaultProps = {
  filterOptions: null,
  onSorting: null,
  onFilter: null,
  onDismiss: PropTypes.func
};

export default ListContextMenu;
