import { AppContext } from 'features/App';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { isInIframe, isInMsTeamsEnvironment } from 'utils/helpers';
import { BackgroundJobsMenu } from 'features/BackgroundJobs';
import { Pivot, PivotItem } from '@fluentui/react';
import HeaderButtonsRow from './HeaderButtonsRow';
import SearchBox from './SearchBox';

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-box-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .header-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    font-weight: bold;
    background: rgb(${({ theme }) => theme.headerTeams.badgeBackground});
    color: rgb(${({ theme }) => theme.headerTeams.badgeForeground});
    border-radius: 50%;
    padding: 0 5px;
    border: 1px solid rgb(${({ theme }) => theme.headerTeams.badgeForeground});
    text-align: center;
  }

  .header-badge-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 11px;
    color: rgb(${({ theme }) => theme.headerTeams.badgeForeground});
    font-weight: bold;
  }
`;

function MsTeamsHeader({
  logout,
  contentToShow,
  onToggleNotifications,
  onToggleTrainer,
  onToggleReleaseNotes
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { selectedProcess, selectedProject } = contentToShow;
  const navigate = useNavigate();
  const location = useLocation();

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { currentUser, tenants, unreadNotifications } = globalAppState;

  const isInRegularIframe = isInIframe() && !isInMsTeamsEnvironment();

  function resetSearchTerm() {
    dispatch({ type: 'searchTerm', data: null });
  }

  function getSelectedKey() {
    const { pathname } = location;

    if (pathname.includes('/process-instances/')) {
      return 'process-instances';
    }

    if (pathname.includes('/projects/')) {
      return 'projects';
    }

    if (pathname.includes('/process-templates/')) {
      return 'process-templates';
    }

    return 'tasks';
  }

  function getProjectPivotItemUrl() {
    let url = '/projects/all';
    if (selectedProject && selectedProject.type === 2) {
      url += `/task-list/${selectedProject.id}/00000000-0000-0000-0000-000000000000`;
    } else if (selectedProject && selectedProject.type === 1) {
      url += `/gantt/${selectedProject.id}`;
    }

    return url;
  }

  function getProcessInstancesPivotItemUrl() {
    let url = '/process-instances/all';

    if (selectedProcess) {
      url += `/${selectedProcess.id}`;
    }

    return url;
  }

  return (
    <HeaderStyled style={{ display: 'flex', justifyContent: 'space-between', height: '50px' }}>
      <Pivot
        overflowBehavior="menu"
        selectedKey={getSelectedKey()}
        onLinkClick={(pivotItem) => {
          if (pivotItem?.props?.url) {
            navigate(pivotItem.props.url);
            resetSearchTerm();
          }
        }}
      >
        {!selectedProcess && !selectedProject && (contentToShow.tasks || isInRegularIframe) ? (
          <PivotItem
            itemKey="tasks"
            onClick={resetSearchTerm}
            url="/tasks/all"
            headerText={t('msTeams.header.pivot.tasks')}
          />
        ) : null}
        {!selectedProcess && (contentToShow.projects || isInRegularIframe) ? (
          <PivotItem
            itemKey="projects"
            onClick={resetSearchTerm}
            url={getProjectPivotItemUrl()}
            headerText={t('msTeams.header.pivot.projects')}
          />
        ) : null}
        {!selectedProject && (contentToShow.processes || isInRegularIframe) ? (
          <PivotItem
            itemKey="process-instances"
            onClick={resetSearchTerm}
            url={getProcessInstancesPivotItemUrl()}
            headerText={t('msTeams.header.pivot.processes')}
          />
        ) : null}
        {!selectedProcess && (contentToShow.processes || isInRegularIframe) ? (
          <PivotItem
            itemKey="process-templates"
            onClick={resetSearchTerm}
            url="/process-templates/landscape"
            headerText={t('msTeams.header.pivot.templates')}
          />
        ) : null}
      </Pivot>
      <BackgroundJobsMenu />
      <div className="search-box-wrapper">
        <SearchBox teamsStyle />
      </div>
      <HeaderButtonsRow
        styles={{
          dividerColor: `rgb(${theme.headerTeams.buttonDivider})`,
          color: `rgb(${theme.headerTeams.buttonForeground})`,
          headerCmdBarButton: {
            commandBarButton: {
              iconHovered: { color: `rgb(${theme.headerTeams.buttonForeground})` },
              iconPressed: { color: `rgb(${theme.headerTeams.buttonForeground})` },
              iconProps: { root: { color: `rgb(${theme.headerTeams.buttonForeground})` } }
            }
          }
        }}
        logout={logout}
        currentUser={currentUser}
        unreadNotifications={unreadNotifications}
        onToggleNotifications={onToggleNotifications}
        onToggleTrainer={onToggleTrainer}
        onToggleReleaseNotes={onToggleReleaseNotes}
        tenants={tenants}
      />
    </HeaderStyled>
  );
}

MsTeamsHeader.propTypes = {
  onToggleNotifications: PropTypes.func.isRequired,
  onToggleTrainer: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  contentToShow: PropTypes.shape({
    selectedProject: PropTypes.object,
    selectedProcess: PropTypes.object,
    tasks: PropTypes.bool,
    processes: PropTypes.bool,
    projects: PropTypes.bool
  }).isRequired,
  onToggleReleaseNotes: PropTypes.func.isRequired
};

export default MsTeamsHeader;
