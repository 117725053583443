// dev environment
const dev = {
  API_URL: 'https://ui-dev-eps.azurewebsites.net/api',
  // API_URL: 'https://staging-eps.azurewebsites.net/api',
  // API_URL: 'https://productivity.evocom.net/api',

  AI_BASE_URL: 'https://app-ai-app-dev-weu.azurewebsites.net/',
  DEV_MODE: true
};

// production environment
const prod = {
  API_URL: '/api',
  AI_BASE_URL: '/',
  DEV_MODE: false
};

const getApiUrl = () => {
  if (import.meta.env.VITE_EVOCOM_PRODUCT === 'ai') {
    return window.config.aiPairedApi;
  }

  if (import.meta.env.MODE === 'development') {
    return dev.API_URL;
  }

  return prod.API_URL;
};

const getAiBaseUrl = () => {
  if (import.meta.env.MODE === 'development') {
    return dev.AI_BASE_URL;
  }

  return prod.AI_BASE_URL;
};

const getI18nLoadPath = () => {
  if (import.meta.env.VITE_EVOCOM_PRODUCT === 'ai') {
    return `/locales/{{lng}}/{{ns}}.json`;
  }

  return `/React/locales/{{lng}}/{{ns}}.json`;
};

const getBaseUrl = () => {
  if (import.meta.env.VITE_EVOCOM_PRODUCT === 'ai') {
    return '/';
  }

  return '/React/';
};

const appConfig = {
  AI_URL: `${getAiBaseUrl()}api`,
  AI_DIRECT_URL: getAiBaseUrl(),
  API_URL: getApiUrl(),
  BASE_URL: getBaseUrl(),
  I18N_LOAD_PATH: getI18nLoadPath(),
  VERSION: import.meta.env.VITE_VERSION,
  DEV_MODE: import.meta.env.MODE === 'development' ? dev.DEV_MODE : prod.DEV_MODE
};

export default appConfig;
