import { useEffect } from 'react';
import { BooleanField, HyperLinkField, RichTextEditor } from 'components';
import numbro from 'numbro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fileContents } from 'services/fetchRequests';
import styled from 'styled-components';
import { columnPropType, instancePropType } from 'types';
import { convertEpUserOrTeamToPersonaProps, formatDate, onOpenWopi } from 'utils/helpers';
import initializeNumbro from 'components/inputs/NumbersField/numbro';
import {
  ActionButton,
  DirectionalHint,
  Facepile,
  Icon,
  ProgressIndicator,
  TooltipHost,
  getTheme
} from '@fluentui/react';
import InteractiveTooltipHost from 'components/surfaces/InteractiveTooltipHost';
import RouteInstancesListNameColumn from './RouteInstancesListNameCell';

const LongTextFieldWrapper = styled.div`
  max-height: 16px;

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
  }
`;

const TagsListCellStyled = styled.div`
  .main-tag-item {
    margin-bottom: 5px;
  }

  .more-tags {
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }
`;

const TagsCalloutStyled = styled.div`
  padding: 10px 10px 0 10px;

  .more-tag-item {
    margin-bottom: 10px;
  }
`;

const TagLinkStyled = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
export default function RouteInstancesListRow({
  column,
  instance,
  onOpenInstancePanel,
  onAbortInstance,
  onDeleteInstance,
  onOpenInstanceView
}) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  function getTagsColumn(tags) {
    const mainTagsArray = [];
    const moreTagsArray = [];

    tags.map((tag, index) => {
      if (index === 0) {
        mainTagsArray.push(
          <TagLinkStyled
            className="main-tag-item"
            role="presentation"
            onClick={() => {
              navigate(`/tags/${tag.id}`);
            }}
          >
            {tag.fullText}
            {tags.length > index + 1 ? ',' : null}
          </TagLinkStyled>
        );
      } else if (index === 1) {
        if (tags.length > 2) {
          mainTagsArray.push(
            <TooltipHost
              directionalHint={DirectionalHint.leftCenter}
              closeDelay={500}
              tooltipProps={{
                onRenderContent: () => <TagsCalloutStyled>{moreTagsArray}</TagsCalloutStyled>
              }}
              calloutProps={{ gapSpace: 10 }}
              styles={{ root: { display: 'inline-block' } }}
            >
              <div className="more-tags">{t('routeInstances.column.moreTags')}</div>
            </TooltipHost>
          );

          moreTagsArray.push(
            <TagLinkStyled
              className="more-tag-item"
              role="presentation"
              onClick={() => {
                navigate(`/tags/${tag.id}`);
              }}
            >
              {tag.fullText}
              {tags.length > index + 1 ? ',' : null}
            </TagLinkStyled>
          );
        } else {
          mainTagsArray.push(
            <TagLinkStyled
              className="main-tag-item"
              role="presentation"
              onClick={() => {
                navigate(`/tags/${tag.id}`);
              }}
            >
              {tag.fullText}
              {tags.length > index + 1 ? ',' : null}
            </TagLinkStyled>
          );
        }
      } else {
        moreTagsArray.push(
          <TagLinkStyled
            className="more-tag-item"
            role="presentation"
            onClick={() => {
              navigate(`/tags/${tag.id}`);
            }}
          >
            {tag.fullText}
            {tags.length > index + 1 ? ',' : null}
          </TagLinkStyled>
        );
      }

      return null;
    });

    return <TagsListCellStyled>{mainTagsArray}</TagsListCellStyled>;
  }

  function onDownloadFile(id, name) {
    const downloadLink = document.createElement('a');

    if (id) {
      fileContents({ id })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((blobUrl) => {
          downloadLink.href = blobUrl;
          downloadLink.download = name || '';

          document.body.appendChild(downloadLink);
          downloadLink.click();

          URL.revokeObjectURL(blobUrl);
        });
    }
  }

  if (column.id === 'default-column-id') {
    return `#${instance.intId}`;
  }
  if (column.id === 'default-column-name') {
    return (
      <RouteInstancesListNameColumn
        onOpenInstancePanel={onOpenInstancePanel}
        onOpenInstanceView={onOpenInstanceView}
        instance={instance}
        onAbortInstance={onAbortInstance}
        onDeleteInstance={onDeleteInstance}
      />
    );
  }
  if (column.id === 'default-column-progress') {
    const canceled = instance.status === 6 || instance.status === 5;
    return (
      <div>
        <Icon
          iconName="Cancel"
          styles={{
            root: {
              display: canceled ? 'inline-block' : 'none',
              position: 'absolute',
              fontSize: 18,
              fontWeight: 'bold',
              zIndex: 100,
              color: '#a80000',
              left: 'calc(50% - 9px)'
            }
          }}
        />
        <ProgressIndicator
          styles={{ progressBar: { backgroundColor: canceled ? '#a3a3a3' : undefined } }}
          barHeight={4}
          percentComplete={instance.percentComplete}
        />
      </div>
    );
  }
  if (column.id === 'default-column-currentTask') {
    return instance.currentStepName;
  }
  if (column.id === 'default-column-createdBy') {
    return instance.creator.name;
  }
  if (column.id === 'default-column-created') {
    return formatDate(instance.creationDate, 'DD.MM.YYYY');
  }
  if (column.id === 'default-column-currentEndDate') {
    return formatDate(instance.currentEndDate, 'DD.MM.YYYY');
  }
  if (column.id === 'default-column-plannedEndDate') {
    return formatDate(instance.plannedEndDate, 'DD.MM.YYYY');
  }
  if (column.id === 'default-column-version') {
    return instance.version;
  }
  if (column.id === 'default-column-tags') {
    return getTagsColumn(instance.tags);
  }

  const index = instance.columns.findIndex((aColumn) => aColumn.id === column.id);
  const columnField = instance.columns[index];

  if (columnField) {
    const { value } = columnField;

    if (value && value.intId) {
      // if id-column
      return `#${value.intId}`;
    }

    if (value && value.name) {
      // if value is a user-object
      return value.name;
    }

    if (columnField.fieldType === 5) {
      // if datefield
      if (columnField.field?.dateFormat === 2) {
        return value ? formatDate(value, 'DD.MM.YYYY, HH:mm') : <div />;
      }

      return value ? formatDate(value, 'DD.MM.YYYY') : <div />;
    }

    if (columnField.fieldType === 2) {
      // if html longtextfield

      const key = typeof value === 'string' ? value?.substring(0, 10) : undefined;

      if (value && typeof value !== 'object') {
        const rte = <RichTextEditor key={key} defaultValue={value} disabled />;

        return (
          <InteractiveTooltipHost
            content={<div style={{ margin: 10 }}>{rte}</div>}
            calloutProps={{ gapSpace: 0 }}
          >
            <LongTextFieldWrapper>{rte}</LongTextFieldWrapper>
          </InteractiveTooltipHost>
        );
      }

      return <div />;
    }

    if (columnField.fieldType === 3) {
      // if numberfield

      if (value) {
        return <NumberField value={value} field={columnField.field} />;
      }

      return <div />;
    }

    if (columnField.fieldType === 4) {
      // if bollean

      const { textNOk, textOk } = columnField.field;

      return (
        <BooleanField
          // force update default value
          key={columnField.value?.toString() ?? 'null'}
          defaultValue={columnField.value}
          textOk={textOk}
          displayResultOnly
          displayCheckBoxOnly
          confirmationFormat={columnField.field.confirmationFormat}
          textNOk={textNOk}
          disabled
          styles={{
            checkboxWrapper: { border: 'none', maxWidth: 'fit-content', margin: 'auto' },
            positiveButton: { root: { height: '20px' } },
            negativeButton: { root: { height: '20px' } }
          }}
        />
      );
    }

    if (columnField.fieldType === 6) {
      // if choicefield
      if (value && Array.isArray(value)) {
        const dispalyValue = value.map(
          (option, index) => `${option.value}${index + 1 === value.length ? '' : ', '}`
        );
        return (
          <div style={{ maxWidth: '300px' }} title={dispalyValue}>
            {dispalyValue}
          </div>
        );
      }

      return value && value.value ? value.value : <div />;
    }

    if (columnField.fieldType === 7) {
      // if person field

      if (value) {
        const values = [...(value.teams || []), ...(value.users || [])];

        let personas = values.map(convertEpUserOrTeamToPersonaProps);
        personas = personas.map((persona) => ({ ...persona, personaName: persona.text }));

        const maxDisplayablePersonas = 2;
        let title = '';

        if (personas.length > maxDisplayablePersonas) {
          title = personas
            .slice(maxDisplayablePersonas)
            .map((persona) => persona.text)
            .join(', ');
        }

        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <Facepile
              personas={personas}
              maxDisplayablePersonas={2}
              personaSize={2}
              overflowButtonType={1}
              overflowButtonProps={{ title, disabled: true }}
            />
          </div>
        );
      }
    }

    if (columnField.fieldType === 9 && columnField.value) {
      // hyperLink
      return (
        <HyperLinkField
          styles={{
            linkWrapperStyles: {
              background: 'none',
              border: 'none',
              padding: 0
            }
          }}
          defaultValue={columnField.value}
          disabled
          key={columnField.id}
        />
      );
    }

    if (columnField.fieldType === 10 && columnField.value) {
      // document
      let menuProps;

      const id = columnField.value.url?.split('id=')[1];

      if (!id) return null;

      const downloadFileClick = () => {
        onDownloadFile(id, columnField.value.text);
      };

      if (columnField.value?.wopiUrl) {
        menuProps = {
          items: [
            {
              key: 'Download',
              text: t('globals.downloadFile'),
              onClick: downloadFileClick,
              iconProps: { iconName: 'Download' }
            },
            {
              key: 'OpenInNewTab',
              onClick: () => {
                onOpenWopi(id);
              },
              text: t('globals.openFile'),
              iconProps: { iconName: 'OpenInNewTab' }
            }
          ]
        };
      }

      return (
        <ActionButton
          title={columnField.value.text}
          menuProps={menuProps}
          onClick={() => {
            if (!columnField.value?.wopiUrl) {
              downloadFileClick();
            }
          }}
          styles={{
            root: {
              color: getTheme().palette.themePrimary,
              padding: 0
            }
          }}
        >
          {columnField.value.text}
        </ActionButton>
      );
    }

    if (columnField.fieldType === 11) {
      // external Data
      return columnField.value;
    }

    if (columnField.fieldType === 12) {
      // Lookup
      return value?.value || '';
    }

    if (columnField.fieldType === 17) {
      // ai
      return value?.choices?.[0]?.text;
    }

    // default case: return value - for example textfields
    return value;
  }

  return <div />;
}

RouteInstancesListRow.propTypes = {
  column: columnPropType.isRequired,
  instance: instancePropType.isRequired,
  onOpenInstancePanel: PropTypes.func.isRequired,
  onDeleteInstance: PropTypes.func.isRequired,
  onAbortInstance: PropTypes.func.isRequired,
  onOpenInstanceView: PropTypes.func.isRequired
};

function NumberField({ value, field }) {
  useEffect(() => {
    initializeNumbro();
  }, []);

  return numbro(value).format({
    thousandSeparated: !!field.useThousandsSeparator,
    mantissa: field.numDecimals || 0
  });
}
