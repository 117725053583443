import { IBlobFile } from 'types';
import { ApiError, ApiErrorType, fetchAIBlob, fetchAIJson } from '../../../services/api2';
import {
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  createSmallApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  LocalApiObject,
  makeApiObjectVariantKey
} from '../object';
import { IApiObjectArray, Ref, Uuid, VoidParams } from './base-types';
import { createFieldRefs } from './utils';
import {
  EMPTY_START_SETTINGS,
  IStartSettingsV1,
  StartSettingsV1
} from '../../../pages/AI/components/StartFormSettings/model';
import { KNOWLEDGE_BASE } from './knowledgeBases';

interface IBaseDataSourceItem {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAgentFile extends IBaseDataSourceItem, Partial<IBlobFile> {
  chatId?: Uuid;
  id: string;
  name: string;
  type: 'file';
}

export interface IPlugin extends IBaseDataSourceItem {
  type: 'plugin';
  isEnabled: boolean;
}

export interface IAgentWebsite {
  id: Uuid;
  createdAt: string;
  updatedAt: string;
  name: string;
  url: string;
}

export interface IAgentKnowledgeBase {
  id: string;
  isEnabled: boolean;
}

export interface IAgentVersion extends IApiObjectData {
  id: Uuid;
  version: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  systemMessage: string;
  publishedForTenant: boolean;
  fields: IStartSettingsV1;
  authorizedTeams: Uuid[] | null;
  knowledgeBases: IAgentKnowledgeBase[] | null;
  plugins: IPlugin[] | null;
  subAgents: ISubAgentVersion[] | null;
  maximumIterations: number;
  temperature: number | null;
}

export interface ISubAgentVersion extends LocalApiObject<IAgentVersion> {
  index: number;
  isTerminator: number;
}

export interface IAgentLatest extends IApiObjectData {
  id: Uuid;
  latest: Ref<IAgentVersion>;
  hasParentAgents: boolean;
  hasSubAgents: boolean;
  disabled: boolean;
}

/** Parses agent data from the backend to IAgentVersion */
export function parseAgentVersionData(data: unknown): LocalApiObject<IAgentVersion> {
  let fields: IStartSettingsV1;
  try {
    const data2 = data as { fields: string | null };
    if (data2.fields) {
      const dataFields = typeof data2.fields === 'string' ? JSON.parse(data2.fields) : data2.fields;
      fields = StartSettingsV1.parse(dataFields);
    } else {
      fields = EMPTY_START_SETTINGS;
    }
  } catch (error) {
    throw new ApiError(ApiErrorType.ClientSchema, 406, error.toString(), undefined, error);
  }

  return { ...(data as LocalApiObject<IAgentVersion>), fields };
}

export function parseAgentData(data: unknown): LocalApiObject<IAgentLatest> {
  const data2 = data as Omit<LocalApiObject<IAgentLatest>, 'latest'>;

  return {
    id: data2.id,
    // FIXME: bad typescript. this is not a ref
    latest: parseAgentVersionData(data) as Ref<IAgentVersion>,
    hasSubAgents: data2.hasSubAgents,
    hasParentAgents: data2.hasParentAgents,
    disabled: data2.disabled
  };
}

export interface IAgentVersionId {
  id: string;
  version: number;
}

export enum AgentIncludes {
  Plugins = 'plugins',
  KnowledgeBases = 'knowledgeBases',
  AuthorizedTeams = 'authorizedTeams',
  SubAgents = 'subAgents'
}

function compileAgentIncludes(includes: AgentIncludes[]): string {
  const search = new URLSearchParams();

  for (const include of includes) {
    search.append('includes', include);
  }

  return search.size ? `?${search.toString()}` : '';
}

export const AGENT_VERSION: IApiObjectType<IAgentVersionId, IAgentVersion, IAgentVersion> = {
  id: 'agent/:id/version/:version',
  createRefs(cache, data): void {
    if (data.knowledgeBases) {
      createFieldRefs(
        cache,
        data,
        data.knowledgeBases.map((kb, index) => ({
          path: `knowledgeBases/${index}`,
          type: KNOWLEDGE_BASE,
          params: () => kb.id,
          includes: [],
          sparseRef: () => ({ id: kb.id, isEnabled: kb.isEnabled })
        }))
      );
    }
  },
  async load(
    cache: IApiObjectCache,
    id: IAgentVersionId,
    abort: AbortSignal,
    { includes }
  ): Promise<IAgentVersion> {
    if (!includes) throw new Error('AGENT_VERSION requires includes');

    const url = `agents/${encodeURIComponent(id.id)}/version/${id.version}${compileAgentIncludes(
      includes as AgentIncludes[]
    )}`;

    const result = await fetchAIJson({ url, abort }, { addUserId: true });

    return createSmallApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(AGENT_VERSION, id, ApiObjectVariant.Small, includes),
      parseAgentVersionData(result)
    ) as IAgentVersion;
  }
};

export const AGENT_LATEST: IApiObjectType<Uuid, IAgentLatest, IAgentLatest> = {
  id: 'agent/:id',
  createRefs(cache: IApiObjectCache, data: IAgentLatest, key): void {
    return createFieldRefs(cache, data, [
      {
        path: 'latest',
        type: AGENT_VERSION,
        params: (data) => ({ id: data.latest.id, version: data.latest.version } as IAgentVersionId),
        includes: key.includes
      }
    ]);
  },
  async load(
    cache: IApiObjectCache,
    id: Uuid,
    abort: AbortSignal,
    { includes }
  ): Promise<IAgentLatest> {
    if (!includes) throw new Error('AGENT_LATEST requires includes');

    const url = `agents/${encodeURIComponent(id)}${compileAgentIncludes(
      includes as AgentIncludes[]
    )}`;

    const result = await fetchAIJson({ url, abort }, { addUserId: true });

    return createSmallApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(AGENT_LATEST, id, ApiObjectVariant.Small, includes),
      parseAgentData(result)
    ) as IAgentLatest;
  }
};

export interface IAgentThumbnail extends IApiObjectData {
  data: Blob | null;
}

export const AGENT_THUMBNAIL: IApiObjectType<IAgentVersionId, IAgentThumbnail> = {
  id: 'agents/:agent/thumbnail',
  // these are technically immutable
  loadDedupIntervalSecs: 86400,
  createRefs() {
    // nothing
  },
  async load(
    cache: IApiObjectCache,
    params: IAgentVersionId,
    abort: AbortSignal
  ): Promise<IAgentThumbnail> {
    const url = `agents/${encodeURIComponent(params.id)}/version/${params.version}/thumbnail`;

    try {
      const result = await fetchAIBlob({ url, abort }, { addUserId: true });
      return createApiObjectDataFromPlainObject(
        cache,
        makeApiObjectVariantKey(AGENT_THUMBNAIL, params),
        { data: result }
      ) as IAgentThumbnail;
    } catch (error) {
      if (error instanceof ApiError && error.status === 404) {
        return createApiObjectDataFromPlainObject(
          cache,
          makeApiObjectVariantKey(AGENT_THUMBNAIL, params),
          { data: null }
        ) as IAgentThumbnail;
      }
      throw error;
    }
  }
};

export const AGENTS: IApiObjectType<VoidParams, IApiObjectArray<IAgentLatest>> = {
  id: 'agents',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IAgentLatest>, key) {
    createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: AGENT_LATEST,
        includes: key.includes,
        params: () => item.id
      })),
      // always insert to force update
      false
    );
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal,
    { includes }
  ): Promise<IApiObjectArray<IAgentLatest>> {
    if (!includes) throw new Error('AGENTS requires includes');

    const url = `agents${compileAgentIncludes(includes as AgentIncludes[])}`;
    const result = await fetchAIJson({ url, abort }, { addUserId: true });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(AGENTS, params, ApiObjectVariant.Small, includes),
      {
        items: result.map(parseAgentData)
      }
    ) as IApiObjectArray<IAgentLatest>;
  }
};
