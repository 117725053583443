import { SupportChoiceGroup } from 'components';
import { acquireTokenPopup, setClientType } from 'features/Authentication/msal';
import { reactPlugin as applicationInsightsReactPlugin } from 'features/ErrorHandling';
import { EvocomLogoLabel } from 'layouts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultNamespace, useTranslation } from 'react-i18next';
import fetchRequest from 'services/api';
import styled, { createGlobalStyle, ThemeProvider, useTheme } from 'styled-components';
import breakpoints from 'utils/breakpoints';
import {
  AnimationStyles,
  Checkbox,
  IPalette,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
  ThemeProvider as FluentThemeProvider
} from '@fluentui/react';
import { useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { HostClientType, app, authentication } from '@microsoft/teams-js';
import {
  createFluentTheme,
  createStyledTheme,
  getThemeStylesAsCss,
  ThemeConfig,
  useSystemThemePreference
} from 'utils/theme';
import { getDefaultTheme } from 'utils/helpers';

const WelcomePageContainer = styled.div<{ $isMsConfigPage?: boolean }>`
  height: 100%;
  line-height: 26px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  .c-welcome-page-logo {
    width: ${({ $isMsConfigPage }) => ($isMsConfigPage ? '320px' : '420px')};

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      width: 320px;
    }
  }

  .c-welcome-page-title {
    color: rgb(${({ theme }) => theme.welcomePage.accentForeground});
    margin-top: 10px;
    font-size: ${({ $isMsConfigPage }) => ($isMsConfigPage ? '31px' : '24px')};
  }

  .c-welcome-page-description {
    margin: ${({ $isMsConfigPage }) =>
      $isMsConfigPage ? '5px auto 5px auto' : '35px auto 25px auto'};
    font-size: ${({ $isMsConfigPage }) => ($isMsConfigPage ? '14px' : '16px')};
    display: inline-block;
    max-width: 440px;
    text-align: center;
  }
`;

type IAppType = 'evocom' | 'ai';

export interface IWelcomePageProps {
  defaultAuthError?: string;
  defaultLoginHint?: string;
  login: (email: string) => void;
  appType?: IAppType;
}

function WelcomePage({
  defaultAuthError,
  defaultLoginHint,
  login,
  appType = 'evocom'
}: IWelcomePageProps) {
  const { t } = useTranslation<DefaultNamespace>(undefined, { useSuspense: false });
  const theme = useTheme();

  const trackError = useTrackEvent(applicationInsightsReactPlugin, 'WPError', undefined);

  const [authError, setAuthError] = useState<string | undefined>();
  const [checkResult, setCheckResult] = useState<unknown>(null);
  const [eMail, setEMail] = useState(null);
  const [isSubmissionDisabled, setIsSubmissionDisabled] = useState(true);
  const [messageBars, setMessageBars] = useState([]);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState({
    checked: false,
    submitted: false
  });
  const [msTeamsEnvironment, setMsTeamsEnvironment] = useState(false);
  const [msTeamsClientType, setMsTeamsClientType] = useState(null);

  useEffect(() => {
    app
      .initialize()
      .then(() => {
        app.getContext().then((context) => {
          setMsTeamsClientType(context.app?.host.clientType);
          setClientType(context.app?.host.clientType);
        });

        setMsTeamsEnvironment(true);
        window.sessionStorage.setItem('msTeamsEnvironment', 'true');
      })
      .catch(() => {
        setMsTeamsEnvironment(false);

        window.sessionStorage.removeItem('msTeamsEnvironment');
      });
  }, []);

  const isMsConfigPage = window.location.href.includes('teams-config');

  const resetMessageBars = useCallback(() => {
    if (authError) {
      setAuthError(null);
    }

    if (messageBars.length) {
      setMessageBars([]);
    }

    if (checkResult) {
      setCheckResult(null);
    }
  }, [authError, checkResult, messageBars.length]);

  const trackAIError = useCallback(
    (dataPayload) => {
      if (applicationInsightsReactPlugin) {
        trackError(dataPayload);
      }
    },
    [trackError]
  );

  useEffect(() => {
    if (defaultLoginHint) {
      setEMail(defaultLoginHint);
    }
  }, [defaultLoginHint]);

  useEffect(() => {
    try {
      setAuthError(defaultAuthError);

      const loginEmail = window.localStorage.getItem('loginEmail');
      const errorToLog =
        defaultAuthError &&
        typeof defaultAuthError === 'string' &&
        !defaultAuthError.includes('login is already in progress')
          ? defaultAuthError
          : null;

      const shouldLogError = errorToLog && loginEmail && !checkResult;

      if (shouldLogError) {
        trackAIError({ email: loginEmail, error: errorToLog });
        if (appType === 'evocom') {
          const body = JSON.stringify({ email: loginEmail, error: errorToLog });
          const url = `Init/FailedLogin?clientId=default`;

          fetchRequest({ url, body, method: 'POST', anonymousCall: true }).then(() => {
            // remove eMail for login error logging
            localStorage.removeItem('loginEmail');
          });
        }
      }
    } catch (error) {
      trackAIError({ error });
    }
  }, [defaultAuthError, checkResult, trackAIError, appType]);

  useEffect(() => {
    setIsSubmissionDisabled(!termsAndConditionsChecked?.checked || !eMail);
  }, [termsAndConditionsChecked?.checked, eMail]);

  useEffect(() => {
    // useEffect to set warning messageBars
    const userCancelledFlow =
      authError && (authError === 'User cancelled the flow.' || authError === 'CancelledByUser');

    if ((!checkResult || (checkResult && msTeamsEnvironment)) && userCancelledFlow) {
      setMessageBars(() => [
        {
          key: 'userCancelledAuthWarning',
          animationStyles: AnimationStyles.slideDownIn20,
          isMultiline: true,
          messageBarType: MessageBarType.warning,
          content: <SupportChoiceGroup resetMessageBars={resetMessageBars} />
        }
      ]);

      setAuthError(null);
    }
    const authAlreadyInProgress = authError && authError.includes('Login_In_Progress');

    if (checkResult && authAlreadyInProgress) {
      setMessageBars(() => [
        {
          key: 'authAlreadyInProgress',
          animationStyles: AnimationStyles.slideDownIn20,
          isMultiline: true,
          messageBarType: MessageBarType.warning,
          content: t('welcomePage.messageBar.loginInProgress')
        }
      ]);

      setAuthError(null);
    }
  }, [t, checkResult, authError, resetMessageBars, msTeamsEnvironment]);

  function onTextFieldChange(value) {
    resetMessageBars();

    if (showLoginButton) {
      setShowLoginButton(false);
    }

    setEMail(value || null);
  }

  function getLoadingSpinnerMessageBarContent(text) {
    return (
      <div style={{ marginLeft: '5px' }}>
        <Spinner
          size={SpinnerSize.small}
          styles={{ label: { color: 'rgb(50, 49, 48)' } }}
          labelPosition="right"
          label={text}
        />
      </div>
    );
  }

  function scrollDown(amount) {
    window.scrollBy(0, amount);
  }

  function setTimeShiftedMessagesBars(checkEmailResult) {
    const { epUser, domainNotAllowed, tenant, userIsLocked } = checkEmailResult;

    let timeOut = 700;
    if (isMsConfigPage) {
      scrollDown(100);
    }

    if (!domainNotAllowed && !tenant && !epUser) {
      setTimeout(() => {
        setMessageBars((prevState) => {
          const prevStateClone = [...prevState];
          const content = t('welcomePage.messageBar.noTenantFound');

          if (prevStateClone.length === 1) {
            prevStateClone[0].content = content;
            prevStateClone[0].messageBarType = MessageBarType.warning;
            prevStateClone[0].isMultiline = true;
            prevStateClone[0].key = 'noTenantFound';
          }

          return prevStateClone;
        });
      }, timeOut);

      return null;
    }

    if (userIsLocked) {
      setTimeout(() => {
        setMessageBars((prevState) => {
          const prevStateClone = [...prevState];
          const content = t('welcomePage.messageBar.userIsLocked');

          if (prevStateClone.length === 1) {
            prevStateClone[0].content = content;
            prevStateClone[0].messageBarType = MessageBarType.warning;
            prevStateClone[0].isMultiline = true;
            prevStateClone[0].key = 'userIsLocked';
          }

          return prevStateClone;
        });
      }, timeOut);

      return null;
    }

    if (domainNotAllowed) {
      setTimeout(() => {
        setMessageBars((prevState) => {
          const prevStateClone = [...prevState];
          const content = t('welcomePage.messageBar.domainNotAllowed');

          if (prevStateClone.length === 1) {
            prevStateClone[0].content = content;
            prevStateClone[0].messageBarType = MessageBarType.warning;
            prevStateClone[0].isMultiline = true;
            prevStateClone[0].key = 'domainNotAllowed';
          }

          return prevStateClone;
        });
      }, timeOut);

      return null;
    }

    setTimeout(() => {
      setMessageBars((prevState) => {
        const prevStateClone = [...prevState];
        prevStateClone[0].messageBarType = MessageBarType.success;
        prevStateClone[0].content = (
          <div>
            <div>{t('welcomePage.messageBar.foundMSTenant')}</div>
            <div>Name: {tenant.name}</div>
            <div>ID: {tenant.tenantId}</div>
          </div>
        );

        prevStateClone.push({
          key: 'searchCompany',
          animationStyles: AnimationStyles.slideDownIn20,
          isMultiline: false,
          messageBarType: MessageBarType.info,
          content: getLoadingSpinnerMessageBarContent(
            t('welcomePage.messageBar.searchEvocomTenant')
          )
        });

        return prevStateClone;
      });

      if (isMsConfigPage) {
        scrollDown(100);
      }
    }, timeOut);

    timeOut += 700;

    setTimeout(() => {
      setMessageBars((prevState) => {
        const prevStateClone = [...prevState];
        prevStateClone[1].messageBarType = MessageBarType.success;
        prevStateClone[1].content = (
          <div>
            <div>Evocom {t('welcomePage.messageBar.tenant')}</div>
            <div>Name: {tenant.name}</div>
          </div>
        );

        return prevStateClone;
      });

      if (isMsConfigPage) {
        scrollDown(100);
      }
    }, timeOut);

    timeOut += 500;
    setTimeout(() => {
      setMessageBars((prevState) => {
        const prevStateClone = [...prevState];

        prevStateClone.push({
          key: 'preparedEP',
          animationStyles: AnimationStyles.slideDownIn20,
          isMultiline: true,
          messageBarType: MessageBarType.success,
          content: t('welcomePage.messageBar.tenantReady')
        });

        return prevStateClone;
      });

      if (isMsConfigPage) {
        scrollDown(100);
      }
    }, timeOut);

    timeOut += 400;

    setTimeout(() => {
      setShowLoginButton(true);

      if (isMsConfigPage) {
        scrollDown(100);
      }
    }, timeOut);

    return null;
  }

  function setEpMsalAuthStartedCookie() {
    // max age = 3 minutes
    document.cookie = 'epMsalAuthStarted=true;max-age=180';
  }

  function setLoginHintAndStartMsalLogin() {
    if (msTeamsEnvironment) {
      window.localStorage.setItem('msTeamsLoginHint', eMail);
      onOpenAuthenticationPopUp();
    } else {
      setEpMsalAuthStartedCookie();
      login(eMail);
    }
  }

  function startLoginProcess(result) {
    setCheckResult(result);

    if (result?.epUser) {
      setLoginHintAndStartMsalLogin();
    } else {
      setTimeShiftedMessagesBars(result);
    }
  }

  function onSubmitEmail() {
    setMessageBars([
      {
        key: 'searchTenant',
        animationStyles: AnimationStyles.slideDownIn20,
        isMultiline: false,
        messageBarType: MessageBarType.info,
        content: getLoadingSpinnerMessageBarContent(t('welcomePage.messageBar.searchMSTenant'))
      }
    ]);

    setIsSubmissionDisabled(true);
    setTermsAndConditionsChecked({ checked: true, submitted: true });

    if (appType === 'ai') {
      setLoginHintAndStartMsalLogin();
    } else {
      const body = JSON.stringify({ check: eMail });
      fetchRequest({
        url: `Init/CheckEp?clientId=default`,
        body,
        method: 'POST',
        anonymousCall: true
      })
        .then(startLoginProcess)
        .catch((error) => {
          trackAIError({ error });
        });
    }
  }

  function onOpenAuthenticationPopUp() {
    if (msTeamsClientType === HostClientType.web) {
      acquireTokenPopup();
    } else {
      authentication.authenticate({
        url: `${window.location.origin}/authentication-start`,
        width: 550,
        height: 535,
        successCallback: () => {
          localStorage.removeItem('msTeamsLoginHint');
          window.location.reload();
        },
        failureCallback: (reason) => {
          setAuthError(reason);
        }
      });
    }
  }

  const title = t('welcomePage.title', { context: appType });
  // Array with subtitle sentences for easier styling

  const logoAndText = (
    <div style={{ marginTop: isMsConfigPage ? '0' : '50px', textAlign: 'center' }}>
      <div style={{ maxWidth: 'max-content', marginRight: 'auto', marginLeft: 'auto' }}>
        <EvocomLogoLabel
          className="c-welcome-page-logo"
          color={`rgb(${theme.welcomePage.logoColor})`}
        />
      </div>
      <div className="c-welcome-page-title">{title}</div>
      <div className="c-welcome-page-description">
        <div style={{ fontWeight: '600', fontSize: '17px' }}>
          {t('welcomePage.subTitle', { context: appType })}.
        </div>
        <div style={{ marginBottom: 35 }}>
          <a
            style={{ color: `rgb(${theme.welcomePage.accentForeground})` }}
            href={appType === 'ai' ? 'https://evocom.ai/' : 'https://evocom.de/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('welcomePage.learnMoreLink', { context: appType })}...
          </a>
        </div>
        {t('welcomePage.subTitle2', { context: appType })}
        <div style={{ marginTop: 5 }}>{t('welcomePage.subTitle3', { context: appType })}</div>
      </div>
    </div>
  );

  function onKeyUpEmailTextField(event) {
    if (event?.key === 'Enter') {
      if (!isSubmissionDisabled) {
        onSubmitEmail();
      }

      event.preventDefault();
    }
  }

  function getMessageBar(messageBar) {
    if (messageBar) {
      const { key, animationStyles, isMultiline, messageBarType, content, truncated } = messageBar;
      const styles = animationStyles ? { ...animationStyles } : undefined;

      return (
        <div key={key} style={styles}>
          <MessageBar
            isMultiline={!!isMultiline}
            truncated={!!truncated}
            styles={{
              innerText: { paddingTop: '2px' },
              root: { marginTop: '3px', transition: 'background-color 0.5s ease-in-out;' },
              iconContainer: {
                display: messageBarType !== MessageBarType.info ? 'inline' : 'none'
              }
            }}
            messageBarType={messageBarType}
          >
            {content}
          </MessageBar>
        </div>
      );
    }

    return null;
  }

  return (
    <WelcomePageContainer $isMsConfigPage={isMsConfigPage}>
      {logoAndText}
      <div style={{ margin: `0 auto auto auto`, maxWidth: '400px' }}>
        <TextField
          value={eMail || ''}
          styles={{
            subComponentStyles: {
              label: { root: { padding: 0 } }
            },
            fieldGroup: [
              {
                height: '40px',
                border: `1px solid rgb(${theme.welcomePage.textFieldOutline})`,
                borderRadius: 4,
                ':after': { borderRadius: 4 }
              }
            ],
            field: { fontSize: '16px', color: `rgb(${theme.welcomePage.textFieldForeground})` }
          }}
          onKeyUp={onKeyUpEmailTextField}
          label={t('welcomePage.emailTextField.label')}
          required
          placeholder={t('welcomePage.emailTextField.placeholder')}
          autoFocus
          type="email"
          onChange={(_, value) => onTextFieldChange(value)}
        />
        {!termsAndConditionsChecked?.submitted && (
          <Checkbox
            styles={{ root: { marginTop: '15px' }, text: { fontSize: '12px' } }}
            label={t('welcomePage.termsAndConditions')}
            onChange={() => {
              setTermsAndConditionsChecked((prevState) => ({
                ...prevState,
                checked: !prevState.checked
              }));
            }}
          />
        )}
        <PrimaryButton
          styles={{
            label: { fontSize: '16px' },
            root: {
              backgroundColor: `rgb(${theme.welcomePage.submitButtonBackground})`,
              color: `rgb(${theme.welcomePage.submitButtonForeground})`,
              width: '100%',
              borderRadius: '4px',
              marginTop: '15px',
              height: '45px',
              marginBottom: '10px'
            },
            rootHovered: {
              backgroundColor: `rgb(${theme.welcomePage.submitButtonHoverBackground})`
            },
            rootPressed: {
              backgroundColor: `rgb(${theme.welcomePage.submitButtonPressedBackground})`
            }
          }}
          text={t('welcomePage.button.confirm')}
          disabled={isSubmissionDisabled}
          onClick={onSubmitEmail}
        />
        {messageBars && messageBars.length ? messageBars.map(getMessageBar) : null}
        {showLoginButton && (
          <PrimaryButton
            styles={{
              label: { fontSize: '16px' },
              root: {
                backgroundColor: `rgb(${theme.welcomePage.submitButtonBackground})`,
                color: `rgb(${theme.welcomePage.submitButtonForeground})`,
                width: '100%',
                marginTop: '15px',
                height: '45px',
                marginBottom: '10px',
                ...AnimationStyles.slideDownIn20,
                borderRadius: '4px'
              },
              rootHovered: {
                backgroundColor: `rgb(${theme.welcomePage.submitButtonHoverBackground})`
              },
              rootPressed: {
                backgroundColor: `rgb(${theme.welcomePage.submitButtonPressedBackground})`
              }
            }}
            text={t('welcomePage.button.login')}
            onClick={setLoginHintAndStartMsalLogin}
          />
        )}
      </div>
      <footer style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <a
          style={{
            fontSize: '12px',
            margin: '0 15px 15px 15px',
            color: `rgb(${theme.welcomePage.secondaryLinkForeground})`
          }}
          href={t('welcomePage.href.privacyPolicy', { context: appType })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('welcomePage.link.privacyPolicy')}
        </a>
        <a
          style={{
            fontSize: '12px',
            margin: '0 15px 15px 15px',
            color: `rgb(${theme.welcomePage.secondaryLinkForeground})`
          }}
          href={t('welcomePage.href.termsOfUse', { context: appType })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('welcomePage.link.termsOfUse')}
        </a>
      </footer>
    </WelcomePageContainer>
  );
}

const WelcomePageGlobalStyle = createGlobalStyle<{ themeConfig: ThemeConfig }>`
    :root {
        ${({ themeConfig }) => getThemeStylesAsCss(themeConfig)}
    }
    
    body {
        background: rgb(${({ theme }) => theme.welcomePage.background});
        color: rgb(${({ theme }) => theme.welcomePage.foreground});
    }
`;

function WelcomePageThemeWrapper({
  defaultAuthError,
  defaultLoginHint,
  login,
  appType
}: IWelcomePageProps) {
  const systemTheme = useSystemThemePreference();
  const themeConfig: ThemeConfig = useMemo(
    () => ({
      fluentPalette: getDefaultTheme() as IPalette,
      dark: systemTheme === 'dark'
    }),
    [systemTheme]
  );
  const theme = useMemo(() => createStyledTheme(themeConfig), [themeConfig]);
  const fluentTheme = useMemo(() => createFluentTheme(themeConfig), [themeConfig]);

  return (
    <FluentThemeProvider theme={fluentTheme} style={{ height: '100%', background: 'transparent' }}>
      <ThemeProvider theme={theme}>
        <WelcomePageGlobalStyle themeConfig={themeConfig} />
        <WelcomePage
          defaultAuthError={defaultAuthError}
          defaultLoginHint={defaultLoginHint}
          login={login}
          appType={appType}
        />
      </ThemeProvider>
    </FluentThemeProvider>
  );
}

export default WelcomePageThemeWrapper;
