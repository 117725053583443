import numbro from 'numbro';
import german from './languages/de-DE';

export default function initializeNumbro() {
  const currentNumbroLanguage = numbro.language();
  const currentLanguage = navigator.language.toLowerCase();

  const currentLanguageIsGerman = currentLanguage.includes('de');

  if (currentLanguageIsGerman && currentNumbroLanguage !== 'de-DE') {
    numbro.registerLanguage(german);
    numbro.setLanguage('de-DE');
  }
}
