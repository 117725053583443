/* eslint-disable max-classes-per-file */

export default abstract class EventEmitter<EventMap extends Record<string, unknown>> {
  private eventListeners = new Map<keyof EventMap, Set<(event: unknown) => void>>();

  addEventListener<E extends keyof EventMap>(
    event: E,
    listener: (event: EventMap[E]) => void,
    options?: { signal?: AbortSignal }
  ) {
    if (!this.eventListeners.has(event)) {
      this.eventListeners.set(event, new Set());
    }
    this.eventListeners.get(event).add(listener);

    if (options?.signal) {
      options?.signal.addEventListener('abort', () => {
        this.removeEventListener(event, listener);
      });
    }
  }

  protected dispatchEvent<E extends keyof EventMap>(event: E, data: EventMap[E]) {
    const listeners = this.eventListeners.get(event);
    if (!listeners) return;
    for (const listener of listeners) listener(data);
  }

  removeEventListener<E extends keyof EventMap>(event: E, listener: (event: EventMap[E]) => void) {
    this.eventListeners.get(event)?.delete(listener);
    if (!this.eventListeners.get(event)?.size) {
      this.eventListeners.delete(event);
    }
  }
}

export class EventEmitterObject<
  EventMap extends Record<string, unknown>
> extends EventEmitter<EventMap> {
  dispatchEvent<E extends keyof EventMap>(event: E, data: EventMap[E]): void {
    super.dispatchEvent(event, data);
  }
}
