import styled from 'styled-components';

const ActivitiesListStyled = styled.div<{ $isScrollable?: boolean }>`
  ${(props) =>
    props.$isScrollable
      ? `
    overflow: auto;
    padding: 1rem;
    `
      : ''}

  .activity-list-item {
    position: relative;
    padding: 7px 7px 10px 10px;
    border-bottom: 1px solid rgb(${({ theme }) => theme.activitiesList.divider});
    display: flex;
    justify-content: space-between;

    &:hover {
      .read-notification-button {
        display: block;
      }

      .new-notification-indicator {
        display: none;
      }
    }
  }

  .activity-item {
    width: 100%;
  }

  .activity-creator {
    font-weight: 700;
    padding-right: 3px;
  }

  .activity-description {
    font-weight: 500;
  }

  .unread-notification {
    border-radius: 3px;
    border-color: transparent;
    background: rgb(
      ${({ theme }) => theme.activitiesList.unreadNotificationBackground} /
        ${({ theme }) => theme.activitiesList.unreadNotificationBackgroundOpacity}
    );

    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;

    &:hover {
      background: rgb(
        ${({ theme }) => theme.activitiesList.unreadNotificationBackground} /
          ${({ theme }) => theme.activitiesList.unreadNotificationHoverBackgroundOpacity}
      );
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .activity-item.active:hover {
    cursor: pointer;
  }

  .route-approval-icon {
    margin-left: 3px;
    margin-right: 3px;
    font-size: 12px;
    transform: rotate(45deg);
  }

  .new-notification-container {
    height: 16px;
    width: 20px;

    .read-notification-button {
      .ms-Button-icon {
        color: rgb(${({ theme }) => theme.activitiesList.notificationIndicatorColor});
        font-size: 16px;
      }

      width: 16px;
      height: 16px;
      margin: auto;
      display: none;
      background-color: transparent;
    }

    .new-notification-indicator {
      margin: 5px 5px 0 auto;
      width: 8px;
      height: 8px;
      background-color: rgb(${({ theme }) => theme.activitiesList.notificationIndicatorColor});
      border-radius: 8px;
      display: block;
    }
  }
`;

export default ActivitiesListStyled;
