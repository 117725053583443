import { ReactNode, useContext, useMemo } from 'react';
import { getResolvedTheme, ResolvedThemeContext, ThemeConfigContext } from './theme';

export default function ResolvedThemeContextProvider({ children }: { children: ReactNode }) {
  const config = useContext(ThemeConfigContext);
  const resolvedTheme = useMemo(() => getResolvedTheme(config), [config]);
  return (
    <ResolvedThemeContext.Provider value={resolvedTheme}>{children}</ResolvedThemeContext.Provider>
  );
}
