import PropTypes from 'prop-types';
import { createRef, useEffect, useLayoutEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled, { useTheme } from 'styled-components';
import { getTeamImageInitials } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import {
  Icon,
  Label,
  NormalPeoplePicker,
  Persona,
  PersonaSize,
  TooltipHost,
  ValidationState
} from '@fluentui/react';

const NormalPeoplePickerStyled = styled(NormalPeoplePicker)`
  .ms-BasePicker-text {
    border-style: ${(props) => (props.borderless ? 'none' : 'solid')};
    border-color: ${(props) =>
      props.isFormPeoplePicker && props.disabled
        ? `rgb(${props.theme.combinedPicker.basePickerDisabledBackground})`
        : ''};
  }

  .ms-BasePicker-input {
    height: 30px;
    background-color: ${({ disabled, theme }) =>
      disabled ? `rgb(${theme.combinedPicker.basePickerDisabledBackground})` : ''};
  }
`;

const LabelStyled = styled(Label)`
  padding-top: ${(props) => (props.isFormPeoplePicker ? '5px' : '0px')};
  padding-bottom: ${(props) => (props.isFormPeoplePicker ? '5px' : '0px')};
`;

const PersonaStyled = styled(Persona)`
  .ms-Persona-details {
    padding-left: 8px;
  }
  .ms-TooltipHost {
    color: ${({ disabled, theme }) =>
      disabled
        ? `rgb(${theme.combinedPicker.personaDisabledForeground})`
        : `rgb(${theme.combinedPicker.personaForeground})`};
  }
`;

function CombinedPicker({
  allowProcessInitiatorSelection,
  borderless,
  createNewUserAllowed,
  createNewTeamAllowed,
  disabled,
  focusPeoplePicker,
  getUserFields,
  hideLabel,
  isFormPeoplePicker,
  itemLimit,
  label,
  onBlur,
  onChange,
  onFilterTextChange,
  placeholder,
  required,
  userId,
  searchTeams,
  searchUser,
  searchUserFields,
  selectedItems,
  type,
  userToFilter,
  className,
  controlled
}) {
  const theme = useTheme();
  let combinedPickerRef = createRef();

  const [inputIdRef] = useState(uuidv4());

  const { t } = useTranslation();
  const processInitiatorText = t('combinedPicker.item.processInitiator.text');
  const processInitiator = {
    assignment: 1,
    text: processInitiatorText,
    secondaryText: t('combinedPicker.item.processInitiator.secondaryText')
  };

  useEffect(() => {
    if (focusPeoplePicker) {
      combinedPickerRef.focusInput();
    }
  }, [combinedPickerRef, focusPeoplePicker]);

  function filterResults(items) {
    if (userToFilter) {
      // remove all userToFilter-items from items-array
      userToFilter.forEach((selectedItems) => {
        const index = items.findIndex((item) =>
          item.userId ? item.userId === selectedItems.userId : item.id === selectedItems.id
        );

        if (index > -1) {
          items.splice(index, 1);
        }
      });
    }

    return items;
  }

  useLayoutEffect(() => {
    const input = document.getElementById(`${inputIdRef}-input-id`);
    const warningIconPlaceholder = document.createElement('div');

    warningIconPlaceholder.id = `${inputIdRef}-warningIconPlaceholder-id`;
    warningIconPlaceholder.className = `${inputIdRef}-warningIconPlaceholder-id`;

    if (input) {
      input.before(warningIconPlaceholder);
    }
  }, [inputIdRef]);

  function getSearchTeamResults(filterText) {
    let url = `Teams`;

    if (filterText && filterText !== '***') {
      url += `?searchTerms=${encodeURIComponent(filterText)}`;
    }

    if (userId) {
      url += `${filterText && filterText !== '***' ? '&' : '?'}userId=${userId}`;
    }

    return fetchRequest({ url })
      .then((teams) =>
        filterResults(teams)
          .filter((team) => team.typeId !== 6)
          .map((team) => ({
            ...team,
            text: team.displayTitle,
            imageInitials: getTeamImageInitials(team.title),
            secondaryText: t('combinedPicker.picker.secondaryText.typeOfTeam')
          }))
      )
      .catch(apiErrorHandler);
  }

  function getUserFieldsResult(filterText) {
    return getUserFields(filterText).map((field) => ({
      ...field,
      text: field.name,
      secondaryText: t('peoplepicker.processfield')
    }));
  }

  function setConsentWarning() {
    const targetContainer = document.getElementById(`${inputIdRef}-warningIconPlaceholder-id`);
    if (targetContainer) {
      const warningIcon = (
        <TooltipHost
          tooltipProps={{
            onRenderContent: () => (
              <div>
                <div>{t('combinedPicker.warning.text1')}</div>
                <div>{t('combinedPicker.warning.text2')}</div>
              </div>
            )
          }}
          calloutProps={{ gapSpace: 15 }}
        >
          <Icon
            iconName="warning"
            styles={{
              root: {
                fontSize: '20px',
                margin: '5px',
                color: `rgb(${theme.combinedPicker.contentWarningIconColor})`,
                selectors: {
                  ':hover': {
                    cursor: 'help'
                  }
                }
              }
            }}
          />
        </TooltipHost>
      );

      const root = createRoot(targetContainer);
      root.render(warningIcon);
    }
  }

  async function getSearchUserResults(filterText) {
    return fetchRequest({
      url: `User/Search?searchTerm=${filterText ? encodeURIComponent(filterText) : ''}&type=${type}`
    })
      .then((res) => {
        if (res.consentNeeded) {
          setConsentWarning();
        }

        return filterResults(res.items).map((user) => ({
          ...user,
          text: user.name,
          imageUrl: user.pictureUrl,
          secondaryText: user.login
        }));
      })
      .catch(apiErrorHandler);
  }

  async function onFilterChanged(filterText) {
    let filteredTeamSearchResult = [];
    let filteredUserSearchResult = [];
    let result = [];

    if (onFilterTextChange) {
      onFilterTextChange(filterText);
    }

    if (filterText) {
      if (searchTeams) {
        filteredTeamSearchResult = await getSearchTeamResults(filterText);
      }

      if (searchUser) {
        filteredUserSearchResult = await getSearchUserResults(filterText);
      }

      // show full array if one resultstype is empty
      if (filteredUserSearchResult.length === 0 || filteredTeamSearchResult.length === 0) {
        if (Array.isArray(filteredTeamSearchResult) && Array.isArray(filteredUserSearchResult)) {
          result.push(...filteredTeamSearchResult, ...filteredUserSearchResult);
        }
      }

      if (filteredUserSearchResult.length > 0 && filteredTeamSearchResult.length > 0) {
        // concat team- and userarray and always return 10 results
        // team results are dynamic, depending on user-results length
        result = filteredUserSearchResult
          .splice(0, 5)
          .concat(
            filteredTeamSearchResult.splice(0, 10 - filteredUserSearchResult.splice(0, 5).length)
          );
      }

      if (searchUserFields && searchUserFields > 0) {
        result = getUserFieldsResult(filterText).concat(result);
      }

      if (
        allowProcessInitiatorSelection &&
        processInitiatorText.toLowerCase().includes(filterText.toLowerCase())
      ) {
        result = [processInitiator, ...result];
      }

      if (createNewUserAllowed && validateInput(filterText) === 0 && result.length === 0) {
        result = [
          {
            text: filterText,
            login: filterText,
            secondaryText: t('combinedPicker.item.addUser')
          }
        ];
      }

      if (createNewTeamAllowed && result.length === 0) {
        result = [
          {
            text: filterText,
            id: '00000000-0000-0000-0000-000000000000',
            title: filterText,
            secondaryText: t('combinedPicker.item.addTeam')
          }
        ];
      }

      return result;
    }

    return null;
  }

  function getSelectedItems() {
    let items = selectedItems;

    if (items && !Array.isArray(items)) {
      items = [items];
    }

    if (items && Array.isArray(items)) {
      const assignedTo = items.map((item) => {
        if (item.assignment === 1) {
          return processInitiator;
        }

        return {
          ...item,
          text: item.name || item.displayTitle || item.title,
          imageUrl: item.pictureUrl
        };
      });

      return assignedTo;
    }

    return [];
  }

  function validateInput(input) {
    // Regular expression for email validation
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // checks if e-mail is already used
    const index = userToFilter.findIndex(
      (user) => user.login.toLowerCase() === input.toLowerCase()
    );

    if (re.test(String(input).toLowerCase()) && createNewUserAllowed && index < 0) {
      return ValidationState.valid;
    }

    return ValidationState.invalid;
  }

  function getTextFromItem(persona) {
    return persona.name || persona.displayTitle;
  }

  function getPersona() {
    const assignedTo = getSelectedItems();
    return (
      <PersonaStyled
        text={assignedTo[0].name || assignedTo[0].title}
        size={PersonaSize.size24}
        imageUrl={assignedTo[0].pictureUrl}
        hidePersonaDetails={false}
        disabled={disabled}
      />
    );
  }

  function onPeopePickerChange(items) {
    onChange(items);

    removeFocusVisibleClass();

    if (!items || (items && !items.length)) {
      combinedPickerRef.focusInput();
    }
  }

  function removeFocusVisibleClass() {
    // get body element
    const body = document.getElementsByTagName('body')[0];
    // remove class from body element caused by fluent ui bug
    body.classList.remove('ms-Fabric--isFocusVisible');
  }

  return (
    <div onMouseLeave={removeFocusVisibleClass}>
      {hideLabel ? null : (
        <LabelStyled
          isFormPeoplePicker={isFormPeoplePicker}
          required={required}
          disabled={disabled}
        >
          {label}
        </LabelStyled>
      )}
      {disabled && getSelectedItems().length > 0 ? (
        getPersona()
      ) : (
        <NormalPeoplePickerStyled
          className={className}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
          }}
          borderless={borderless}
          isFormPeoplePicker={isFormPeoplePicker}
          getTextFromItem={getTextFromItem}
          componentRef={(input) => {
            combinedPickerRef = input;
          }}
          styles={{
            root: { background: `rgb(${theme.combinedPicker.background})` },
            text: {
              selectors: {
                '::after': {
                  border: borderless ? 'none' : undefined
                }
              }
            }
          }}
          resolveDelay={100}
          defaultSelectedItems={controlled ? null : getSelectedItems()}
          selectedItems={controlled ? getSelectedItems() : null}
          onResolveSuggestions={onFilterChanged}
          pickerSuggestionsProps={{
            mostRecentlyUsedHeaderText: t('peoplepicker.recent'),
            noResultsFoundText: t('peoplepicker.noresult'),
            loadingText: t('peoplepicker.loading'),
            showRemoveButtons: false
          }}
          inputProps={{ placeholder, autoComplete: 'nope', id: `${inputIdRef}-input-id` }}
          itemLimit={itemLimit}
          onChange={onPeopePickerChange}
          onValidateInput={validateInput}
          disabled={disabled}
          onEmptyInputFocus={() => onFilterChanged('***')}
        />
      )}
    </div>
  );
}

CombinedPicker.propTypes = {
  allowProcessInitiatorSelection: PropTypes.bool,
  borderless: PropTypes.bool,
  createNewUserAllowed: PropTypes.bool,
  createNewTeamAllowed: PropTypes.bool,
  disabled: PropTypes.bool,
  focusPeoplePicker: PropTypes.bool,
  getUserFields: PropTypes.func,
  hideLabel: PropTypes.bool,
  isFormPeoplePicker: PropTypes.bool,
  itemLimit: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFilterTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  userId: PropTypes.string,
  required: PropTypes.bool,
  searchTeams: PropTypes.bool,
  searchUser: PropTypes.bool,
  searchUserFields: PropTypes.bool,
  selectedItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.number,
  userToFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  controlled: PropTypes.bool
};

CombinedPicker.defaultProps = {
  allowProcessInitiatorSelection: false,
  borderless: false,
  createNewUserAllowed: false,
  createNewTeamAllowed: false,
  disabled: false,
  focusPeoplePicker: false,
  getUserFields: null,
  hideLabel: false,
  isFormPeoplePicker: false,
  itemLimit: 1,
  label: null,
  onBlur: null,
  placeholder: null,
  required: false,
  searchTeams: false,
  searchUser: false,
  searchUserFields: false,
  selectedItems: null,
  type: 0,
  userToFilter: [],
  onFilterTextChange: null,
  className: null
};

export default CombinedPicker;
